import { twMerge } from "tailwind-merge";

import "./Input.scss";

const Input = ({
  id,
  className = "",
  label,
  value,
  adornment,
  onChange,
  type = "text",
  ...rest
}) => {
  return (
    <div
      className={twMerge(
        "input-container max-w-96",
        `input-${type}`,
        className,
      )}
    >
      <label htmlFor={id} className={value && "filled"}>
        {label}
        {rest.required && !rest.readOnly ? " *" : ""}
      </label>
      <input id={id} type={type} value={value} onChange={onChange} {...rest} />
      {adornment && <div className="adornment">{adornment}</div>}
    </div>
  );
};

export default Input;
