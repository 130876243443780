import axiosInstance from "api/axiosInstance";

/**
 *  GET /user
 *
 *  read the current user
 */
export const getCurrentUser = async (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  return await axiosInstance
    .get("/user", { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  PUT /user
 *
 *  update the current user
 */
export const updateCurrentUser = async ({ firstName, lastName }) => {
  let data = {
    first_name: firstName,
    last_name: lastName,
  };
  return await axiosInstance
    .put("/user", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  DELETE /user
 *
 *  delete the current user
 */
export const deleteCurrentUser = async () => {};

/**
 *  POST /user/reset/password
 *
 *  update the password of the current user
 */
export const updateUserPassword = async (newPassword) => {
  let data = {
    new_password: newPassword,
  };
  return await axiosInstance
    .post("/user/reset/password", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  GET /user/revoke/token
 *
 *  revoke the token
 */
export const revokeToken = async (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  return await axiosInstance
    .get("/user/revoke/token", { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  GET /user/management
 *
 *  get all users
 */
export const getUsers = async () => {
  return await axiosInstance
    .get("/user/management")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  PUT /user/management/{user_id}
 *
 *  update a user's role or block the user
 */
export const updateUser = async ({ id, roleId, isBlocked }) => {
  let data = {
    user_role_id: roleId,
    is_blocked: isBlocked,
  };
  return await axiosInstance
    .put(`/user/management/${id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  DELETE /user/management/{user_id}
 *
 *  delete a user by id
 */
export const deleteUser = async (id) => {
  return await axiosInstance
    .delete(`/user/management/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
