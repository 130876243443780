import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { toast } from "react-toastify";

import { useUser } from "context/UserContext";

import { updateUserPassword } from "api/users";

import Input from "components/Form/Input/Input";
import Password from "components/Form/Input/Password";
import Button from "components/Form/Button/Button";

const Profile = () => {
  const { userData, updateUserData } = useUser();
  const { t } = useTranslation();
  const [email, setEmail] = useState(userData.email);
  const [firstName, setFirstName] = useState(userData.first_name);
  const [lastName, setLastName] = useState(userData.last_name);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [isSamePassword, setIsSamePassword] = useState("");
  const [role] = useState(userData.user_role);
  const [isActive] = useState(userData.is_active);
  const [isConfirmed] = useState(userData.is_confirmed);

  useEffect(() => {
    setIsSamePassword(password2 === "" ? null : password1 === password2);
  }, [password1, password2]);

  const updatePassword = async (password) => {
    try {
      const response = await updateUserPassword(password);
      if (response.code === "ERR_BAD_REQUEST")
        toast.error(t(response.response.data.detail));
      else
        toast.success(
          t(
            "Your password was changed successfully. Next time login with your new password.",
          ),
        );
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  const handleUpdateProfile = () => {
    // updating user data happens with the use of Context
    try {
      const response = updateUserData({
        email: email,
        first_name: firstName,
        last_name: lastName,
        is_active: isActive,
        is_confirmed: isConfirmed,
      });

      if (response.code === "ERR_BAD_REQUEST")
        toast.error(t(response.response.data.detail));
      else toast.success(t("User data updated!"));
    } catch (error) {
      console.error("Error updating user data:", error);
    }

    // updating password happens with an API call and only if something was typed in
    if (password1 && isSamePassword) {
      updatePassword(password1);
    }
  };

  return (
    <>
      <div className="profile view">
        <div className="header">
          <h1>{t("My profile")}</h1>
          <div className="flex mb-5">
            <div className="description max-w-4xl"></div>
          </div>
        </div>
        <div className="content p-1">
          <form className="w-full max-w-[540px] flex flex-col">
            <div className="my-5 flex flex-col">
              <h3 className="mb-5">{t("User information")}</h3>
              <Input
                id="email"
                className="my-4"
                label={t("Username (E-mail)")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                readOnly
              />
              <div className="flex gap-4 flex-wrap my-4">
                <Input
                  id="first-name"
                  label={t("First name")}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <Input
                  id="first-name"
                  label={t("Last name")}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="my-5 flex flex-col">
              <h3 className="mb-5">
                {t("You can change your password here:")}
              </h3>
              <Password
                id="setPassword1"
                className="my-4"
                label={t("Type the new password")}
                value={password1}
                onChange={(e) => setPassword1(e.target.value)}
              />
              <div className="my-4">
                <Password
                  id="password2"
                  label={t("Re-type the new password")}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                />
                <p
                  className={twMerge(
                    "input-message",
                    isSamePassword ? "valid" : "error",
                  )}
                >
                  {isSamePassword === null
                    ? ""
                    : t(
                        isSamePassword
                          ? "Passwords are identical"
                          : "Passwords do not match",
                      )}
                </p>
              </div>
            </div>
            <div className="my-5 flex flex-col self-start">
              <h3 className="mb-5">{t("Account information")}</h3>
              <div className="p-4 grid grid-cols-2 gap-4 border rounded">
                {t("Role")}
                <strong>{t(role)}</strong>
                {t("Account is active?")}
                {isActive ? (
                  <i className="ri-check-line text-green-600"></i>
                ) : (
                  <i className="ri-close-line text-red-600"></i>
                )}
                {t("Account is confirmed?")}
                {isConfirmed ? (
                  <i className="ri-check-line text-green-600"></i>
                ) : (
                  <i className="ri-close-line text-red-600"></i>
                )}
              </div>
              <div className="mt-5">
                <Button
                  className="btn-primary mt-8"
                  onClick={handleUpdateProfile}
                >
                  {t("Update")}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

Profile.displayName = "Profile";

export default Profile;
