import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer, cssTransition } from "react-toastify";

const LoginLayout = () => {
  const { t } = useTranslation();
  const toastySlide = cssTransition({
    enter: "slide-top",
    exit: "slide-top-hide",
  });

  return (
    <div className="h-screen flex flex-col">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        transition={toastySlide}
      />
      <main className="main-content terms pt-6 flex flex-col items-center scroll-smooth">
        <div className="flex flex-col items-center">
          <h1 className="app-title font-bold text-[6rem] text-primary leading-none tracking-tighter">
            edups
          </h1>
          <p className="app-subtitle ms-2 mt-2 mb-5 text-[17px] leading-none text-slate-500 font-medium">
            Educational prescribing system
          </p>
        </div>
        <Outlet />
      </main>
      <footer className="login-footer mt-auto mb-4 text-sm text-center text-slate-600">
        <p>
          &copy; {new Date().getFullYear()} <strong>edups</strong> - Educational
          prescribing system. {t("For support send an e-mail to")}{" "}
          <a href="mailto:">support@edups.nl</a>
        </p>
      </footer>
    </div>
  );
};

export default LoginLayout;
