import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Button, { ButtonGroup } from "components/Form/Button/Button";

// import { AuthContext } from "./../../context/AuthContext";

const SessionTypeModal = ({ onSubmit, ...props }) => {
	const { t } = useTranslation();

	return (
		<Modal
			title={t("Create new session")}
			isOpen={props.isOpen}
			onClose={props.onClose}
		>
			<form className="w-[460px] flex flex-col gap-4 items-center justify-center">
				<p className="text-center whitespace-pre-line">
					{t(
						"Choose which kind of session you want to create.\nFor a class session you need to have the class ID that you received from your teacher.",
					)}
				</p>
				<ButtonGroup className="gap-6">
					<Button
						className="w-28 min-w-fit pt-2 flex flex-col gap-2"
						onClick={() => onSubmit("new_home_session")}
					>
						<i className="ri-home-line text-xl" />
						{t("Home")}
					</Button>
					<Button
						className="w-28 min-w-fit pt-2 flex flex-col gap-2"
						onClick={() => onSubmit("new_class_session")}
					>
						<i className="ri-school-line text-xl" />
						{t("Class")}
					</Button>
				</ButtonGroup>
			</form>
		</Modal>
	);
};

export default SessionTypeModal;
