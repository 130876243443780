export const getHelpText = (sessionStatus) => {
	switch (sessionStatus) {
		case "Draft":
			return "You can add, edit or delete treatments plan or submit the session for evaluation.\nOnce submitted, the session cannot be edited anymore nor deleted.";
		case "Submitted":
			return "You can provide feedback on the entire session, by patient and by prescription.";
		case "Reviewed":
			return "This session was already reviewed. Editing or deleting it is not possible anymore.";
		default:
			return "";
	}
};
