import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useUser } from "context/UserContext";
import { createClass, getClasses, updateClass, deleteClass } from "api/classes";
import { getDate } from "utils/utils";

import Badge from "components/Badge/Badge";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import FilterTabs from "components/Tabs/FilterTabs";
import Table, { Actions } from "components/Table/Table";
import NewClassModal from "components/NewClassModal";
import ClassModal from "components/ClassModal";
import ConfirmationModal from "components/ConfirmationModal";

const Classes = () => {
	// modals - states
	const [showNewClassModal, setShowNewClassModal] = useState(false);
	const [showEditClassModal, setShowEditClassModal] = useState(false);
	const [showPublishClassModal, setShowPublishClassModal] = useState(false);
	const [showArchiveClassModal, setShowArchiveClassModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// hooks
	const navigate = useNavigate();
	const { userData } = useUser();
	const { t } = useTranslation();

	// table - states
	const [classes, setClasses] = useState([]);
	const [selectedClass, setSelectedClass] = useState({});
	const [refreshTable, setRefreshTable] = useState(true);
	const [classId, setClassId] = useState();
	const [description, setDescription] = useState();
	const [isActive, setIsActive] = useState();
	const [isPublished, setIsPublished] = useState();

	// table - filters

	const filters = useMemo(
		() => [
			{
				key: "author",
				label: t("My classes"),
				value: `${userData.first_name} ${userData.last_name}`,
				isActive: true, // flag to signify compound condition
			},
			{ key: "is_active", label: t("All classes"), value: 1 },
			{ key: "is_active", label: t("Archived"), value: 0 },
		],
		[t, userData],
	);
	const [filter, setFilter] = useState(filters[0]);

	// table - actions

	const getRowActions = useCallback(
		(row) => {
			const actions = [];

			if (row.is_active) {
				actions.push({
					name: "toggle-publish",
					icon: row.is_published ? "ri-eye-off-line" : "ri-eye-line",
					command: () => {
						setSelectedClass(row);
						setShowPublishClassModal(true);
					},
					title: t(row.is_published ? "Unpublish class" : "Publish class"),
					disabled: row.creator_id !== userData.id,
				});
			}

			actions.push({
				name: "toggle-archive",
				icon: row.is_active
					? "ri-inbox-archive-line"
					: "ri-inbox-unarchive-line",
				command: () => {
					setSelectedClass(row);
					setShowArchiveClassModal(true);
				},
				title: t(row.is_active ? "Archive class" : "Unarchive class"),
				disabled: row.creator_id !== userData.id,
			});

			actions.push({
				name: "delete",
				icon: "ri-delete-bin-line",
				command: () => handleDelete(row),
				title: t("Delete class"),
				disabled: row.total_sessions !== 0 || row.creator_id !== userData.id,
			});

			return actions;
		},
		[t, userData],
	);

	// table - columns

	const columns = useMemo(
		() =>
			[
				{
					Header: t("ID"),
					accessor: "id",
					cssClass: "min-cell-width text-center",
					Cell: ({ row: { original } }) => (
						<Badge
							className={`badge-outline ${
								!original.is_active ? "line-through" : ""
							}`}
							value={original.id}
							title={t("You have to send the students this class ID")}
						/>
					),
				},
				{
					Header: t("Class name"),
					accessor: "description",
					cssClass: "font-medium w-2/6 truncate",
				},
				{
					Header: t("Author"),
					accessor: "creator",
					cssClass: "w-1/6 whitespace-nowrap",
					//disableGlobalFilter: true,
				},
				{
					Header: t("Status"),
					accessor: "is_published",
					cssClass: "w-1/6 text-center",
					Cell: ({ row: { original } }) => (
						<Badge
							className="text-xs leading-none"
							value={
								!original.is_active
									? "archived"
									: original.is_published
									? "published"
									: "not published"
							}
						/>
					),
				},
				{
					Header: t("Submissions"),
					accessor: "total_sessions",
					cssClass: "text-center min-cell-width",
					Cell: ({ cell: { value } }) => (value === 0 ? "-" : value),
				},
				{
					Header: t("Date created"),
					accessor: "created_at",
					cssClass: "text-center min-cell-width",
					Cell: ({ cell: { value } }) => getDate(value),
				},
				{
					Header: t("Actions"),
					accessor: "accessor",
					disableSortBy: true,
					cssClass: "actions text-center min-cell-width pr-5",
					Cell: ({ row: { original } }) => {
						return <Actions row={original} actions={getRowActions(original)} />;
					},
				},
			].filter((item) => item !== false),
		[getRowActions, t],
	);

	// table - action handlers

	function handleView(row) {
		navigate(`/classes/${row?.id}`);
	}

	async function handlePublishClass() {
		const isPublished = !selectedClass.is_published;

		try {
			const response = await updateClass({
				classId: selectedClass.id,
				description: selectedClass.description,
				isActive: selectedClass.is_active,
				isPublished,
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			} else {
				setRefreshTable(true);

				if (isPublished) {
					toast.success(t("Class published!"));
				} else {
					toast.success(t("Class unpublished!"));
				}
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowPublishClassModal(false);
		}
	}

	async function handleArchiveClass() {
		const isActive = !selectedClass.is_active;

		try {
			const response = await updateClass({
				classId: selectedClass.id,
				description: selectedClass.description,
				isPublished: selectedClass.is_published,
				isActive,
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			} else {
				setRefreshTable(true);

				if (isActive) {
					toast.success(t("Class unarchived!"));
				} else {
					toast.success(t("Class archived!"));
				}
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowArchiveClassModal(false);
		}
	}

	const handleDelete = (row) => {
		setClassId(row.id);
		setDescription(row.description);
		setShowDeleteModal(true);
	};

	const deleteConfirmed = async () => {
		try {
			const response = await deleteClass(classId);

			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
				console.log(response);
			} else {
				toast.success(t("Class deleted!"));
				setClassId(null);
				setRefreshTable(true);
			}
		} catch (error) {
			toast.error(t("An error occurred while deleting the class."));
			console.log(error);
		} finally {
			setShowDeleteModal(false);
		}
	};

	const applyFilters = (data, filter) => {
		const { key, value, isActive } = filter;

		return data.filter((item) => {
			switch (key) {
				case "is_active":
					return item.is_active === value;
				case "author":
					if (isActive) {
						return item.creator === value && item.is_active === 1;
					}
					return item.creator === value;
				default:
					return true; // no specific filter matches
			}
		});
	};

	// modals - handlers

	const createNewClass = async (description) => {
		const response = await createClass({
			description: description,
			isPublished: 0,
			isActive: 1,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
		} else {
			setClassId(response.class_id);
			setRefreshTable(true);
			navigate(`/classes/${response.class_id}`);
		}
	};

	// fetch data

	useEffect(() => {
		const getUnreviewedCount = (data) => {
			/*
			const me = `${userData.first_name} ${userData.last_name}`;
			const count = data.filter((item) => {
				return item.creator === me && item.reviewed_at === null;
			}).length;
			*/
		};

		getUnreviewedCount(classes);
	}, [userData, classes]);

	useEffect(() => {
		if (!refreshTable) return;

		const fetchClasses = async () => {
			try {
				const response = await getClasses();

				if (response.code === "ERR_BAD_REQUEST") {
					toast.error(t(response.response.data.detail));
					console.log(response);
				} else {
					setClasses(response);
				}
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshTable(false);
			}
		};

		fetchClasses();
	}, [refreshTable, t]);

	return (
		<>
			<div className="view">
				<div className="header">
					<h1>{t("Classes")}</h1>
					<HelpTooltip
						text={t(
							"List of all the classes and their statuses. Teachers can define a new class and send the Class-ID to the students. The view is only visible for teachers and admin.",
						)}
					/>
				</div>

				<div className="content">
					<FilterTabs
						filters={filters}
						onChange={(index) => setFilter(filters[index])}
					/>
					<Table
						data={applyFilters(classes, filter)}
						columns={columns}
						searchLabel={t("Search by ID, description or author")}
						searchColumns={["id", "description", "creator"]}
						searchGlobal={true}
						newAction={() => setShowNewClassModal(true)}
						newActionLabel={t("Create new class")}
						onRowClick={(row) => handleView(row.original)}
					/>
				</div>
			</div>

			{showNewClassModal && (
				<NewClassModal
					onClose={() => setShowNewClassModal(false)}
					onSubmit={(description) => {
						setDescription(description);
						createNewClass(description);
					}}
				/>
			)}

			{showEditClassModal && (
				<ClassModal
					classId={classId}
					description={description}
					isActive={isActive}
					isPublished={isPublished}
					onClose={() => setShowEditClassModal(false)}
					onSubmit={(description, isActive, isPublished) => {
						updateClass({
							classId,
							description,
							isActive,
							isPublished,
						});
						setIsActive(isActive);
						setIsPublished(isPublished);
						setClassId(null);
						setRefreshTable(true);
					}}
				/>
			)}

			{showPublishClassModal && (
				<ConfirmationModal
					title={
						selectedClass.is_published
							? t("Unpublish class?")
							: t("Publish class?")
					}
					message={
						selectedClass.is_published
							? t("Are you sure you want to unpublish this class?")
							: t("Are you sure you want to publish this class?")
					}
					yesMessage={
						selectedClass.is_published ? t("Unpublish") : t("Publish")
					}
					onClose={() => setShowPublishClassModal(false)}
					onSubmit={handlePublishClass}
				/>
			)}

			{showArchiveClassModal && (
				<ConfirmationModal
					title={
						selectedClass.is_active
							? t("Archive class?")
							: t("Unarchive class?")
					}
					message={
						selectedClass.is_active
							? t("Are you sure you want to archive this class?")
							: t("Are you sure you want to unarchive this class?")
					}
					yesMessage={selectedClass.is_active ? t("Archive") : t("Unarchive")}
					onClose={() => setShowArchiveClassModal(false)}
					onSubmit={handleArchiveClass}
				/>
			)}

			{showDeleteModal && (
				<ConfirmationModal
					title={t("Delete class")}
					message={t(
						'Are you sure you want to delete the class "{{ title }}" (Class ID {{ id }})?\nThis operation cannot be undone.',
						{ title: description, id: classId },
					)}
					yesMessage={t("Delete")}
					onClose={() => {
						setClassId(null);
						setShowDeleteModal(false);
					}}
					onSubmit={deleteConfirmed}
				/>
			)}
		</>
	);
};

Classes.displayName = "Classes";

export default Classes;
