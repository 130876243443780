import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useUser } from "context/UserContext";

import Modal from "components/Modal/Modal";
import Input from "components/Form/Input/Input";
import Button, { ButtonGroup } from "components/Form/Button/Button";
import Password from "components/Form/Input/Password";

const LoginModal = ({ onSubmit, onClose }) => {
	const { t } = useTranslation();
	const { userData } = useUser();
	const [username, setUsername] = useState(userData?.email || "");
	const [password, setPassword] = useState("");

	return (
		<Modal title={t("Re-authenticate")} onClose={onClose}>
			<form
				className="w-80 flex flex-col gap-5"
				onSubmit={() => onSubmit({ username, password })}
			>
				<Input
					id="username"
					className="mt-4"
					label="E-mail"
					type="email"
					value={username}
					required
					onChange={(e) => setUsername(e.target.value)}
				/>
				<Password
					id="password"
					className="mt-4"
					label={t("Password")}
					name="password"
					value={password}
					required
					onChange={(e) => setPassword(e.target.value)}
				/>
				<ButtonGroup>
					<Button onClick={onClose}>{t("Log out")}</Button>
					<Button
						className="btn-primary"
						onClick={() => onSubmit({ username, password })}
					>
						{t("Log in")}
					</Button>
				</ButtonGroup>
			</form>
		</Modal>
	);
};

export default LoginModal;
