import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  getDifficulties,
  createDifficulty,
  updateDifficulty,
  deleteDifficulty,
} from "api/difficulties";

import {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
} from "api/categories";

import {
  getCourses,
  createCourse,
  updateCourse,
  deleteCourse,
} from "api/courses";

import HelpTooltip from "components/HelpTooltip/HelpTooltip";
/*
import Input from "components/Form/Input/Input";
import Password from "components/Form/Input/Password";
import DateInput from "components/Form/DateInput/DateInput";
import Select from "components/Form/Select/Select";
import Combo from "components/Form/Combo/Combo";
import Textarea from "components/Form/Textarea/Textarea";
import Button from "components/Form/Button/Button";
*/

import Table, { Actions } from "components/Table/Table";

import DifficultyModal from "components/DifficultyModal";
import CategoryModal from "components/CategoryModal";
import CourseModal from "components/CourseModal";
import ConfirmationModal from "components/ConfirmationModal";

const Settings = () => {
  const { t } = useTranslation();

  const [difficulties, setDifficulties] = useState([]);
  const [difficulty, setDifficulty] = useState({});
  const [refreshDifficultyTable, setRefreshDifficultyTable] = useState(true);
  const [showDifficultyModal, setShowDifficultyModal] = useState(false);
  const [showDeleteDifficultyModal, setShowDeleteDifficultyModal] =
    useState(false);

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({});
  const [refreshCategoryTable, setRefreshCategoryTable] = useState(true);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);

  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState({});
  const [refreshCourseTable, setRefreshCourseTable] = useState(true);
  const [showCourseModal, setShowCourseModal] = useState(false);
  const [showDeleteCourseModal, setShowDeleteCourseModal] = useState(false);

  const difficultyActions = useMemo(
    () => [
      {
        name: "delete",
        icon: "ri-delete-bin-line",
        command: (row) => {
          setDifficulty(row);
          setShowDeleteDifficultyModal(true);
        },
        title: t("Delete difficulty level"),
      },
    ],
    [t],
  );

  const difficultyColumns = useMemo(
    () => [
      {
        Header: t("Title"),
        accessor: "name",
      },
      {
        Header: t("Description"),
        accessor: "description",
      },
      {
        Header: t("Actions"),
        accessor: "accessor",
        disableSortBy: true,
        cssClass: "text-center min-cell-width pr-5",
        Cell: ({ row: { original } }) =>
          difficultyActions && (
            <Actions row={original} actions={difficultyActions} />
          ),
      },
    ],
    [difficultyActions, t],
  );

  const categoryActions = useMemo(
    () => [
      {
        name: "delete",
        icon: "ri-delete-bin-line",
        command: (row) => {
          setCategory(row);
          setShowDeleteCategoryModal(true);
        },
        title: t("Delete category"),
      },
    ],
    [t],
  );

  const categoryColumns = useMemo(
    () => [
      {
        Header: t("Title"),
        accessor: "name",
      },
      {
        Header: t("Description"),
        accessor: "description",
      },
      {
        Header: t("Actions"),
        accessor: "accessor",
        disableSortBy: true,
        cssClass: "text-center min-cell-width pr-5",
        Cell: ({ row: { original } }) =>
          categoryActions && (
            <Actions row={original} actions={categoryActions} />
          ),
      },
    ],
    [categoryActions, t],
  );

  const courseActions = useMemo(
    () => [
      {
        name: "delete",
        icon: "ri-delete-bin-line",
        command: (row) => {
          setCourse(row);
          setShowDeleteCourseModal(true);
        },
        title: t("Delete course"),
      },
    ],
    [t],
  );

  const courseColumns = useMemo(
    () => [
      {
        Header: t("Title"),
        accessor: "name",
      },
      {
        Header: t("Description"),
        accessor: "description",
      },
      {
        Header: t("Actions"),
        accessor: "accessor",
        disableSortBy: true,
        cssClass: "text-center min-cell-width pr-5",
        Cell: ({ row: { original } }) =>
          courseActions && <Actions row={original} actions={courseActions} />,
      },
    ],
    [courseActions, t],
  );

  /*
  const selectOptions = [
    { value: 1, label: "Option 1" },
    { value: 2, label: "Option 2" },
  ];

  const comboOptions = [
    { value: 1, label: "A 1" },
    { value: 2, label: "Option 2" },
  ];

  const [input, setInput] = useState("");
  const [password, setPassword] = useState("");
  const [date, setDate] = useState("");
  const [combo, setCombo] = useState(null);
  const [select, setSelect] = useState("");
  const [textarea, setTextarea] = useState("");
  */

  const handleSubmitDifficultyModal = async (data) => {
    try {
      let response = null;

      if (difficulty?.id) {
        response = await updateDifficulty({
          id: difficulty.id,
          name: data.name,
          description: data.description,
        });
      } else {
        response = await createDifficulty({
          name: data.name,
          description: data.description,
        });
      }

      if (response.status === 200 || response.status === 201) {
        setRefreshDifficultyTable(true);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleDeleteDifficuly = async () => {
    try {
      const response = await deleteDifficulty(difficulty.id);

      if (response.status === 200 || response.status === 201) {
        toast.success(t("Difficulty level deleted!"));
        setRefreshDifficultyTable(true);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.detail || "An error occurred";
      toast.error(t(errorMessage));
    } finally {
      setShowDeleteDifficultyModal(false);
    }
  };

  const handleSubmitCategoryModal = async (data) => {
    try {
      let response = null;

      if (category?.id) {
        response = await updateCategory({
          id: category.id,
          name: data.name,
          description: data.description,
        });
      } else {
        response = await createCategory({
          name: data.name,
          description: data.description,
        });
      }

      if (response.status === 200 || response.status === 201) {
        setRefreshCategoryTable(true);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleDeleteCategory = async () => {
    try {
      const response = await deleteCategory(category.id);

      if (response.status === 200 || response.status === 201) {
        toast.success(t("Category deleted!"));
        setRefreshCategoryTable(true);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.detail || "An error occurred";
      toast.error(t(errorMessage));
    } finally {
      setShowDeleteCategoryModal(false);
    }
  };

  const handleSubmitCourseModal = async (data) => {
    try {
      let response = null;

      if (course?.id) {
        response = await updateCourse({
          id: course.id,
          name: data.name,
          description: data.description,
        });
      } else {
        response = await createCourse({
          name: data.name,
          description: data.description,
        });
      }

      if (response.status === 200 || response.status === 201) {
        setRefreshCourseTable(true);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleDeleteCourse = async () => {
    try {
      const response = await deleteCourse(course.id);

      if (response.status === 200 || response.status === 201) {
        toast.success(t("Course deleted!"));
        setRefreshCourseTable(true);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.detail || "An error occurred";
      toast.error(t(errorMessage));
    } finally {
      setShowDeleteCourseModal(false);
    }
  };

  // get the difficulty levels

  useEffect(() => {
    if (!refreshDifficultyTable) return;

    const fetchDifficulties = async () => {
      try {
        const response = await getDifficulties();
        setDifficulties(response.data.data);
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setRefreshDifficultyTable(false);
      }
    };

    fetchDifficulties();
  }, [refreshDifficultyTable]);

  // get the categories

  useEffect(() => {
    if (!refreshCategoryTable) return;

    const fetchCategories = async () => {
      try {
        const response = await getCategories();
        console.log("response", response);
        setCategories(response.data.data);
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setRefreshCategoryTable(false);
      }
    };

    fetchCategories();
  }, [refreshCategoryTable]);

  // get the courses

  useEffect(() => {
    if (!refreshCourseTable) return;

    const fetchCourses = async () => {
      try {
        const response = await getCourses();
        console.log("response", response);
        setCourses(response.data.data);
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setRefreshCourseTable(false);
      }
    };

    fetchCourses();
  }, [refreshCourseTable]);

  return (
    <>
      <div className="my-sessions view">
        <div className="header">
          <h1>{t("Settings")}</h1>
          <HelpTooltip
            text={t("This view includes administrative settings.")}
          />
        </div>

        <div className="flex flex-col gap-6">
          <h2>{t("Stories")}</h2>
          <div className="flex gap-8">
            <div className="w-1/2">
              <h3>{t("Manage the difficulty levels")}</h3>
              <p className="mt-1 text-sm text-slate-500">
                {t(
                  "If a previously used level is deleted, the level of the Story is reset to 'none'.",
                )}
              </p>
              <Table
                columns={difficultyColumns}
                data={difficulties}
                showSearch={false}
                newAction={() => {
                  setDifficulty({});
                  setShowDifficultyModal(true);
                }}
                onRowClick={(row) => {
                  setDifficulty(row.original);
                  setShowDifficultyModal(true);
                }}
              />
            </div>
            <div className="w-1/2">
              <h3>{t("Manage the categories")}</h3>
              <p className="mt-1 text-sm text-slate-500">
                {t(
                  "If a previously used category is deleted, the category of the Story is reset to 'none'.",
                )}
              </p>
              <Table
                columns={categoryColumns}
                data={categories}
                showSearch={false}
                newAction={() => {
                  setCategory({});
                  setShowCategoryModal(true);
                }}
                onRowClick={(row) => {
                  setCategory(row.original);
                  setShowCategoryModal(true);
                }}
              />
            </div>
          </div>
          <div className="w-3/4">
            <h3 className="mt-8">{t("Manage the courses")}</h3>
            <p className="mt-1 text-sm text-slate-500">
              {t(
                "If a previously used course is deleted, the course of the Story is reset to 'none'.",
              )}
            </p>
            <Table
              columns={courseColumns}
              data={courses}
              showSearch={false}
              newAction={() => {
                setCourse({});
                setShowCourseModal(true);
              }}
              onRowClick={(row) => {
                setCourse(row.original);
                setShowCourseModal(true);
              }}
            />
          </div>
        </div>
        {/*
        <div className="my-8 ml-6 w-96">
          <h2>Fields & Select elements</h2>
          <Input
            className="my-8"
            label="Input"
            value={input}
            required
            onChange={(e) => setInput(e.target.value)}
          />
          <Input
            className="my-8"
            label="Input"
            value={input}
            adornment="kg/m³"
            required
            onChange={(e) => setInput(e.target.value)}
          />
          <Password
            className="my-8"
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <DateInput
            className="my-8"
            label="Date"
            value={date}
            // pattern="[0-9]{2}-[0-9]{2}-(19|20)\d{2}"
            // maxLength="10"
            // minLength="10"
            placeholder={t("DD-MM-YYYY")}
            required
            onChange={(e) => setDate(e.target.value)}
          />
          <Combo
            className="my-8"
            label="Combo"
            options={comboOptions}
            value={combo}
            onChange={setCombo}
          />
          <Select
            className="my-8"
            label="Select"
            options={selectOptions}
            value={select}
            onChange={setSelect}
          />
          <Textarea
            className="my-8"
            label="Textarea"
            value={textarea}
            onChange={(e) => setTextarea(e.target.value)}
          />
        </div>
        <div className="my-8 ml-6">
          <Button className="btn-primary" onClick={(e) => alert("clicked!")}>
            Primary
          </Button>
          <Button onClick={(e) => alert("clicked!")}>Standard</Button>
          <Button className="disabled" onClick={(e) => alert("clicked!")}>
            Disabled
          </Button>
        </div>
        <div className="mt-8">
          <h3>Output</h3>
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Input</strong>
                </td>
                <td>{input}</td>
              </tr>
              <tr>
                <td>
                  <strong>Password</strong>
                </td>
                <td>{password}</td>
              </tr>
              <tr>
                <td>
                  <strong>Date</strong>
                </td>
                <td>{date}</td>
              </tr>
              <tr>
                <td>
                  <strong>Combo</strong>
                </td>
                {combo && (
                  <td>
                    [{combo.value}] "{combo.label}"
                  </td>
                )}
              </tr>
              <tr>
                <td>
                  <strong>Select</strong>
                </td>
                <td>{select.label}</td>
              </tr>
              <tr>
                <td>
                  <strong>Textarea</strong>
                </td>
                <td>{textarea}</td>
              </tr>
            </tbody>
          </table>
        </div>
        */}
      </div>

      {showDifficultyModal && (
        <DifficultyModal
          name={difficulty.name}
          description={difficulty.description}
          onClose={() => setShowDifficultyModal(false)}
          onSubmit={(data) => {
            handleSubmitDifficultyModal(data);
            setShowDifficultyModal(false);
          }}
        />
      )}

      {showDeleteDifficultyModal && (
        <ConfirmationModal
          title={t("Delete difficulty level")}
          message={t("Are you sure you want to delete this difficulty level?")}
          yesMessage={t("Delete")}
          destructive={true}
          onClose={() => setShowDeleteDifficultyModal(false)}
          onSubmit={handleDeleteDifficuly}
        />
      )}

      {showCategoryModal && (
        <CategoryModal
          name={category.name}
          description={category.description}
          onClose={() => setShowCategoryModal(false)}
          onSubmit={(data) => {
            handleSubmitCategoryModal(data);
            setShowCategoryModal(false);
          }}
        />
      )}

      {showDeleteCategoryModal && (
        <ConfirmationModal
          title={t("Delete category level")}
          message={t("Are you sure you want to delete this category level?")}
          yesMessage={t("Delete")}
          destructive={true}
          onClose={() => setShowDeleteCategoryModal(false)}
          onSubmit={handleDeleteCategory}
        />
      )}

      {showCourseModal && (
        <CourseModal
          name={course.name}
          description={course.description}
          onClose={() => setShowCourseModal(false)}
          onSubmit={(data) => {
            handleSubmitCourseModal(data);
            setShowCourseModal(false);
          }}
        />
      )}

      {showDeleteCourseModal && (
        <ConfirmationModal
          title={t("Delete course level")}
          message={t("Are you sure you want to delete this course level?")}
          yesMessage={t("Delete")}
          destructive={true}
          onClose={() => setShowDeleteCourseModal(false)}
          onSubmit={handleDeleteCourse}
        />
      )}
    </>
  );
};

Settings.displayName = "Settings";

export default Settings;
