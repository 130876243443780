import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Input from "components/Form/Input/Input";
import Button from "components/Form/Button/Button";
import Select from "components/Form/Select/Select";

import { getDifficulties } from "api/difficulties";
import { getCategories } from "api/categories";
import { getCourses } from "api/courses";

const FilterMenu = ({
  newAction = null,
  difficulties = [],
  categories = [],
  courses = [],
  filter,
  setFilter = null,
  resultCount,
  onClose = null,
}) => {
  const { t } = useTranslation();

  const handleFilterChange = (type, value) => {
    if (!value)
      setFilter((prevFilter) => {
        const { [type]: _, ...rest } = prevFilter;
        return rest;
      });
    else
      setFilter((prevFilter) => ({
        ...prevFilter,
        [type]: value,
      }));
  };

  return (
    // show a button with a filter icon
    // to make the div with filter options visible

    <div className="filter-box w-[320px] p-4 pt-6 my-3 me-1 flex flex-col gap-5 border rounded bg-gray-100 text-sm absolute top-[35px] right-0 shadow-md">
      {difficulties && (
        <Select
          label={t("Difficulty")}
          options={difficulties}
          value={difficulties.find((item) => item.value === filter?.difficulty)}
          noneLabel={t("None")}
          onChange={(value) => handleFilterChange("difficulty", value.value)}
        />
      )}

      {categories && (
        <Select
          label={t("Category")}
          options={categories}
          value={categories.find((item) => item.value === filter?.category)}
          noneLabel={t("None")}
          onChange={(value) => handleFilterChange("category", value.value)}
        />
      )}

      {courses && (
        <Select
          label={t("Course")}
          options={courses}
          value={courses.find((item) => item.value === filter?.course)}
          noneLabel={t("None")}
          onChange={(value) => handleFilterChange("course", value.value)}
        />
      )}

      <div className="mt-4 flex justify-between">
        <Button
          className="btn-action min-w-fit"
          onClick={() => {
            setFilter({});
            onClose && onClose();
          }}
        >
          {t("Clear")}
        </Button>
        <Button
          className="btn-primary min-w-fit"
          onClick={onClose}
          disabled={resultCount === 0}
        >
          {resultCount === 0
            ? t("0 results")
            : t("Show {{ count }} results", {
                count: resultCount,
              })}
        </Button>
      </div>
    </div>
  );
};

const TableRow = ({ index, className, data, onClick = null }) => {
  const { t } = useTranslation();

  if (!data) return null;

  const classes = [
    "p-4 overflow-hidden border rounded flex flex-col shrink-0 gap-1 hover:shadow hover:bg-gray-100 cursor-pointer",
  ];
  if (className) classes.push(className);

  const handleRowClick = () => {
    if (onClick) onClick(data);
  };

  return (
    <div className={classes.join(" ")} onClick={handleRowClick}>
      <div className="meta text-sm flex items-start gap-6 text-gray-500">
        {!data?.is_active && (
          <div
            className="mr-auto text-red-500 font-semibold"
            title={t("Inactive")}
          >
            <i className="ri-close-circle-line"></i> {t("Inactive")}
          </div>
        )}

        <div className="ml-auto" title={t("Story ID")}>
          {t("Story ID")} {data.id}
        </div>

        <div title={t("Author")}>
          <i className="ri-account-circle-line"></i> {data.creator}
        </div>
      </div>

      <h3 className="text-sm uppercase">{data.title}</h3>

      <div className="story-description max-w-4xl text-sm">
        <p>{data.description}</p>
      </div>
    </div>
  );
};

const StoriesTableStatic = ({
  data,
  showSearch = true,
  searchLabel = "Search by title or description (min. 3 characters)",
  onRowClick,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [difficulties, setDifficulties] = useState([]);
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const { t } = useTranslation();

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const handleRowClick = (event, row) => {
    if (onRowClick) {
      setSelectedRow(row.id);
      onRowClick(row);
    }
  };

  // get the difficulties

  useEffect(() => {
    const fetchDifficulties = async () => {
      try {
        const response = await getDifficulties();
        setDifficulties(
          response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        );
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchDifficulties();
  }, []);

  // get the categories

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategories();
        setCategories(
          response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        );
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchCategories();
  }, []);

  // get the courses

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await getCourses();
        setCourses(
          response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        );
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    if (!data) return null;

    const filterData = () => {
      return data.filter((story) => {
        const matchesDifficulty = filter.difficulty
          ? story.difficulty_id === filter.difficulty
          : true;
        const matchesCategory = filter.category
          ? story.category_id === filter.category
          : true;
        const matchesCourse = filter.course
          ? story.course_id === filter.course
          : true;
        const matchesSearch =
          searchInput && searchInput.length > 2
            ? story.title.toLowerCase().includes(searchInput.toLowerCase()) ||
              story.description
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : true;
        return (
          matchesDifficulty && matchesCategory && matchesCourse && matchesSearch
        );
      });
    };

    setFilteredData(filterData());
  }, [filter, searchInput, data]);

  return (
    <div className="h-full overflow-hidden flex flex-col gap-y-2 py-1">
      <div className="search-wrapper pt-2 p-1 flex gap-2 items-center relative">
        {showSearch && (
          <Input
            className="w-96"
            label={t(searchLabel)}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        )}
        <Button
          className="btn-action ms-auto mr-1 p-0 text-2xl relative"
          title={t("Filter")}
          onClick={() => setShowFilterMenu((prev) => !prev)}
        >
          <i
            className={showFilterMenu ? "ri-close-line" : "ri-filter-3-line"}
          ></i>
          <div className="absolute bottom-0 right-[1px] leading-none text-xs text-slate-500">
            {Object.keys(filter).length}
          </div>
        </Button>
        {showFilterMenu && (
          <FilterMenu
            difficulties={difficulties}
            categories={categories}
            courses={courses}
            filter={filter}
            resultCount={filteredData.length}
            setFilter={handleFilterChange}
            onClose={() => setShowFilterMenu(false)}
          />
        )}
      </div>

      {filteredData && (
        <div className="wrapper max-h-full overflow-y-auto flex flex-col gap-2">
          {filteredData.map((story, idx) => (
            <TableRow
              key={story.id}
              index={idx}
              data={story}
              className={
                selectedRow === story.id ? "bg-selected hover:bg-selected" : ""
              }
              onClick={(e) => handleRowClick(e, story)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

TableRow.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

StoriesTableStatic.propTypes = {
  data: PropTypes.array.isRequired,
};

export default StoriesTableStatic;
