import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Input from "components/Form/Input/Input";
import Button, { ButtonGroup } from "components/Form/Button/Button";

const SessionNameModal = (props) => {
	const { t } = useTranslation();
	const [sessionDesc, setSessionDesc] = useState(props?.description || "");

	const handleSubmit = () => {
		props.onSubmit(sessionDesc);
		setSessionDesc("");
		props.onClose();
	};

	return (
		<Modal
			title={
				props?.description ? t("Edit session name") : t("New home session")
			}
			className="w-[480px]"
			onClose={props.onClose}
			onSubmit={props.onSubmit}
		>
			<form className="flex flex-col gap-4 w-full" onSubmit={handleSubmit}>
				<p>
					{t(
						"Enter a name for the session. The new session will appear in the sessions table with this name.",
					)}
				</p>
				<Input
					id="session-id"
					className="mt-4 max-w-full"
					label={t("Session name")}
					type="text"
					name="session-desc"
					value={sessionDesc}
					onChange={(e) => setSessionDesc(e.target.value)}
					autoFocus
					required
				/>
				<ButtonGroup>
					<Button className="btn-primary">
						{props?.description ? t("Save") : t("Create new home session")}
					</Button>
				</ButtonGroup>
			</form>
		</Modal>
	);
};

export default SessionNameModal;
