import { useAuth } from "context/AuthContext";

import Button from "components/Form/Button/Button";

const Logout = () => {
	const { setAuthData } = useAuth();
	return (
		<>
			<form
				className="w-80 p-5 my-5 border rounded shadow flex flex-col gap-5"
				onSubmit={(e) => {
					e.preventDefault();
					setAuthData(null);
				}}
			>
				<p className="mt-4">Are you sure?</p>
				<Button>Log out</Button>
			</form>
		</>
	);
};

Logout.displayName = "Logout";

export default Logout;
