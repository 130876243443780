import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Button, { ButtonGroup } from "components/Form/Button/Button";

// import { AuthContext } from "./../../context/AuthContext";

const ClassIDModal = (props) => {
	const { t } = useTranslation();
	const [classId, setClassId] = useState("");

	const handleSubmit = () => {
		props.onSubmit(classId);
		setClassId("");
		props.onClose();
	};

	return (
		<Modal
			title={t("New class session")}
			isOpen={props.isOpen}
			onClose={props.onClose}
			onSubmit={props.onSubmit}
		>
			<form
				className="flex flex-col gap-6 w-full items-center"
				onSubmit={handleSubmit}
			>
				<p>{t("Enter the Class ID you received from your teacher")}</p>
				<input
					id="class-id"
					className="w-36 px-2 py-1 border border-slate-300 rounded focus-within:outline-none focus:outline-none text-4xl text-center"
					label={t("Class ID")}
					pattern="[0-9]*"
					maxLength="6"
					name="class-id"
					value={classId}
					onChange={(e) => setClassId(e.target.value)}
					autoFocus
					autoComplete="off"
					required
				/>
				<ButtonGroup>
					<Button className="btn-primary">{t("Create class session")}</Button>
				</ButtonGroup>
			</form>
		</Modal>
	);
};

export default ClassIDModal;
