import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useFilters, useGlobalFilter, useSortBy } from "react-table";
import { Droppable, Draggable } from "@hello-pangea/dnd";
import { twMerge } from "tailwind-merge";

import { getDifficulties } from "api/difficulties";
import { getCourses } from "api/courses";
import { getCategories } from "api/categories";

import Button from "components/Form/Button/Button";
import Input from "components/Form/Input/Input";
import Select from "components/Form/Select/Select";

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

const TableRow = ({
  id,
  index,
  className,
  row,
  actions = [],
  isDragDisabled = false,
  onRowClick,
}) => {
  const { t } = useTranslation();

  return (
    <Draggable
      key={id.toString()}
      index={index}
      draggableId={id.toString()}
      isDragDisabled={!!isDragDisabled}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          style={{ ...provided.draggableProps.style }}
          className={twMerge(
            "p-4 overflow-hidden border rounded flex flex-col gap-2 bg-white hover:shadow hover:bg-gray-100",
            className,
          )}
          {...provided.draggableProps}
        >
          <div className="meta text-sm flex items-start gap-6 text-gray-500">
            <div
              className={twMerge(
                "px-1 rounded hover:bg-gray-200",
                isDragDisabled && "hidden",
              )}
              {...provided.dragHandleProps}
            >
              <i className="ri-draggable"></i>
            </div>

            {!row?.is_active && (
              <div
                className="mr-auto text-red-500 font-semibold"
                title={t("Disabled")}
              >
                <i className="ri-close-circle-line"></i> {t("Disabled")}
              </div>
            )}

            <div className="ml-auto" title={t("Story ID")}>
              {t("Story ID")} {row.id}
            </div>

            {row.difficulty && (
              <div className="meta text-sm text-gray-500">
                <div title={t("Difficulty")}>
                  <i className="ri-bar-chart-2-line"></i> {row.difficulty}
                </div>
              </div>
            )}

            <div title={t("Author")}>
              <i className="ri-account-circle-line"></i> {row.creator}
            </div>
          </div>

          <h3 className="-mb-1 text-sm uppercase">{row.title}</h3>

          <div className="story-description max-w-4xl text-sm">
            <p>{row.description}</p>
          </div>

          <div className="-m-2 flex flex-col">
            {actions.length > 0 && (
              <div className="story-actions ml-auto text-sm">
                <Actions row={row} actions={actions} />
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

const Actions = ({ row, actions = [] }) => {
  return (
    <div className="actions-wrapper whitespace-nowrap flex gap-2">
      {actions.map(function (action, idx) {
        return (
          <Button
            key={idx}
            className="btn-action"
            stopPropagation={true}
            onClick={() => action.command(row)}
            title={action.title}
          >
            <i className={action.icon}></i>
          </Button>
        );
      })}
    </div>
  );
};

const StoriesTable = ({
  id,
  data = [],
  actions = [],
  searchLabel = "Search by title or description",
  searchColumns = ["title", "description"],
  searchGlobal = false,
  newAction = null,
  newActionLabel = "",
  initialState,
  noDataMessage = "The story list is empty.",
  showSearch = true,
  showFilter = false,
  isDragDisabled = false,
  isLoading = false,
  onRowClick,
  onMoveRow,
  onDragStart,
  onDragEnd,
}) => {
  const [filterInput, setFilterInput] = useState("");
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [difficulties, setDifficulties] = useState([]);
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation();

  const searchFilter = useCallback((rows, columns, query) => {
    return rows.filter((row) => {
      return [row.values["title"] || "", row.values["description"] || ""]
        .join(" ")
        .toLowerCase()
        .includes(query.trim().toLowerCase());
    });
  }, []);

  const columns = useMemo(
    () =>
      [
        {
          Header: "ID",
          accessor: "id",
        },
        {
          Header: "Title",
          accessor: "title",
        },
        {
          Header: "Description",
          accessor: "description",
        },
        {
          Header: "Active",
          accessor: "is_active",

          // put the inactive items at the bottom
          sortType: (rowA, rowB) => {
            if (rowA.original.is_active === rowB.original.is_active) return 0;
            return rowA.original.is_active ? -1 : 1;
          },
        },
        {
          Header: "For class study",
          accessor: "is_class",
        },
        {
          Header: "For home study",
          accessor: "is_self_study",
        },
        {
          Header: "Shared",
          accessor: "is_shared",
        },
        {
          Header: "Patient ID",
          accessor: "patient_id",
        },
        {
          Header: "Category",
          accessor: "category_id",
        },
        {
          Header: "Difficulty",
          accessor: "difficulty_id",
        },
        {
          // add a new column based on the difficulty_id
          Header: "Difficulty",
          id: "difficulty",
          accessor: (row) =>
            difficulties.find((d) => d.value === row.difficulty_id)?.label,
        },
        {
          Header: "Course",
          accessor: "course_id",
        },
        {
          Header: "Source",
          accessor: "source_id",
        },
        {
          Header: "Created by",
          accessor: "creator",
        },
        {
          Header: "Created on",
          accessor: "created_at",
        },
        actions.length > 0 && {
          Header: "Actions",
          accessor: "accessor",
          cssClass: "text-center min-cell-width pr-5",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Actions row={original} actions={actions} />
          ),
        },
      ].filter((item) => item !== false),
    [difficulties, actions],
  );

  /**
   * define a customFilter function to include only specific columns
   * if no searchColumns defined, it will do a global search
   * using useCallback to cache the function definition between re-renders
   */
  const customFilter = useCallback(
    (rows, columns, query) => {
      return rows.filter((row) => {
        return searchColumns
          ? searchColumns.some((column) => {
              return row.values[column]
                .toString()
                .toLowerCase()
                .includes(query);
            })
          : Object.values(row.values).some((cell) => {
              return cell?.toString().toLowerCase().includes(query);
            });
      });
    },
    [searchColumns],
  );

  // update the state when input changes
  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilterInput(value);
    if (searchColumns) {
      if (searchColumns.length === 1) setFilter(searchColumns[0], value);
      if (searchColumns?.length > 1) setGlobalFilter(value);
    } else setGlobalFilter(value);
  };

  // handler to clear filter on Escape key
  /*
  const handleKeyDown = (e, accessor) => {
    if (e.key === "Escape") {
      setShowFilterPopover(false);
    }
  };
  */

  // handler to reset all filters
  const resetAllFilters = () => {
    setAllFilters([]);
    setShowFilterPopover(false);
  };

  const handleRowClick = (event, row) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    setFilter, // The useFilter Hook provides a way to set the filter
    setAllFilters,
    setGlobalFilter,
    state: { filters },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: "is_active", desc: false }],
      },
      globalFilter: searchFilter ? searchFilter : customFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
  );

  // get the difficulties

  useEffect(() => {
    const fetchDifficulties = async () => {
      try {
        const response = await getDifficulties();
        setDifficulties(
          response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        );
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchDifficulties();
  }, []);

  // get the courses

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await getCourses();
        setCourses(
          response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        );
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchCourses();
  }, []);

  // get the categories

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategories();
        setCategories(
          response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        );
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchCategories();
  }, []);

  const difficultyFilter = filters.find((f) => f.id === "difficulty_id");
  const courseFilter = filters.find((f) => f.id === "course_id");
  const categoryFilter = filters.find((f) => f.id === "category_id");

  return (
    <div className="h-full overflow-hidden flex flex-col gap-y-2">
      {(showSearch || newAction) && (
        <div className="search-wrapper pt-2 p-1 flex gap-2 items-center">
          {showSearch && (
            <Input
              className="w-96"
              label={t(searchLabel)}
              value={filterInput || ""}
              onChange={handleFilterChange}
            />
          )}
          {showFilter && (
            // show a button with a filter icon
            // to make the div with filter options visible
            <div className={`relative ${!newAction ? "ml-auto" : ""}`}>
              <Button
                className="btn-action mr-1 p-0 text-2xl relative"
                title={t("Filter")}
                onClick={() => setShowFilterPopover((prev) => !prev)}
              >
                <i
                  className={
                    showFilterPopover ? "ri-close-line" : "ri-filter-3-line"
                  }
                ></i>
                <div className="absolute bottom-0 right-[1px] leading-none text-xs text-slate-500">
                  {Object.keys(filters).length}
                </div>
              </Button>
              {showFilterPopover && (
                <div className="filter-box w-[320px] p-4 pt-6 my-2 flex flex-col gap-5 border rounded bg-gray-100 text-sm absolute top-[35px] right-0 shadow-md">
                  <Select
                    label={t("Difficulty")}
                    options={difficulties}
                    value={difficulties.find(
                      (item) => item.value === difficultyFilter?.value,
                    )}
                    noneLabel={t("None")}
                    onChange={(value) =>
                      setFilter("difficulty_id", value.value)
                    }
                  />

                  <Select
                    label={t("Category")}
                    options={categories}
                    value={categories.find(
                      (item) => item.value === categoryFilter?.value,
                    )}
                    noneLabel={t("None")}
                    onChange={(value) => setFilter("category_id", value.value)}
                  />

                  <Select
                    label={t("Course")}
                    options={courses}
                    value={courses.find(
                      (item) => item.value === courseFilter?.value,
                    )}
                    noneLabel={t("None")}
                    onChange={(value) => setFilter("course_id", value.value)}
                  />

                  <div className="mt-4 flex justify-between">
                    <Button
                      className="btn-action min-w-fit"
                      onClick={resetAllFilters}
                    >
                      {t("Clear")}
                    </Button>
                    <Button
                      className="btn-primary min-w-fit"
                      disabled={rows.length === 0}
                      onClick={() => setShowFilterPopover(false)}
                    >
                      {rows.length === 0
                        ? t("0 results")
                        : t("Show {{ count }} results", {
                            count: rows.length,
                          })}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
          {newAction && (
            <Button
              className={`${
                !newActionLabel ? "min-w-min btn-action" : "btn-primary"
              } m-0 ml-auto ${newAction ? "" : "disabled"} `}
              title={t("Create new story")}
              onClick={newAction}
            >
              <i className="ri-add-line font-black text-xl -my-2"></i>
              {newActionLabel && t(newActionLabel)}
            </Button>
          )}
        </div>
      )}

      <StrictModeDroppable droppableId={id}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={twMerge(
              "h-full overflow-hidden rounded transition duration-300",
              snapshot.isDraggingOver ? "bg-gray-100" : "bg-white",
            )}
          >
            <div className="table-wrapper max-h-full p-1 overflow-y-auto overflow-x-hidden">
              <div className="table w-full" {...getTableProps()}>
                <div {...getTableBodyProps()} className="flex flex-col gap-2">
                  {isLoading ? (
                    <div className="p-2 mt-32 text-center text-sm whitespace-pre-wrap leading-normal text-gray-500">
                      {t("Loading stories...")}
                    </div>
                  ) : rows.length ? (
                    rows.map((row, index) => {
                      prepareRow(row);
                      return (
                        <TableRow
                          id={row.original.id}
                          index={index}
                          key={index}
                          row={row.values}
                          actions={actions}
                          isDragDisabled={isDragDisabled}
                          onRowClick={handleRowClick}
                        />
                      );
                    })
                  ) : (
                    <div className="p-2 mt-32 text-center text-sm whitespace-pre-wrap leading-normal text-gray-500">
                      {t(noDataMessage)}
                    </div>
                  )}

                  {provided.placeholder}
                </div>
              </div>
            </div>
          </div>
        )}
      </StrictModeDroppable>
    </div>
  );
};

export default StoriesTable;
export { TableRow };
