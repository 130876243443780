import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import FilterTabs from "components/Tabs/FilterTabs";
import Badge from "components/Badge/Badge";
import SessionsTable, { Actions } from "components/SessionsTable/SessionsTable";
import SessionTypeModal from "components/SessionTypeModal";
import SessionNameModal from "components/SessionNameModal";
import ClassIDModal from "components/ClassIDModal";
import ConfirmationModal from "components/ConfirmationModal";

import { createSession, deleteSession, getSessions } from "api/sessions";
import { getClass, getStoriesFromClass } from "api/classes";
import { createTreatmentPlan } from "api/treatmentPlans";

import { useUser } from "context/UserContext";

import { getDate } from "utils/utils";

const Sessions = ({ readOnly = false }) => {
	// table - states
	const [sessions, setSessions] = useState([]);
	const [session, setSession] = useState();
	const [refreshTable, setRefreshTable] = useState(true);

	// modals - states
	const [showSessionTypeModal, setShowSessionTypeModal] = useState(false);
	const [showSessionNameModal, setShowSessionNameModal] = useState(false);
	const [showClassIDModal, setShowClassIDModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// hooks
	const { canI, amI } = useUser();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const helpTooltipText = [
		t(
			"Start a new session and choose the session type. You can edit or delete sessions as long they are not submitted.",
		),
	];
	if (!amI("Student"))
		helpTooltipText.push(
			t("\nTeachers can review here the submitted sessions."),
		);

	/**
	 * filterTabs
	 */
	const filterTabs = [
		{ key: "is_active", label: t("All sessions"), value: 1 },
		{
			key: "session_type",
			label: t("Class sessions"),
			value: "Class-Study",
		},
		{
			key: "session_type",
			label: t("Home sessions"),
			value: "Self-Study",
		},
	];

	const filterTabsTeacher = [
		{
			key: "status_id",
			label: t("Submitted sessions"),
			value: 2,
		},
		{
			key: "status_id",
			label: t("Reviewed sessions"),
			value: 3,
		},
		{ key: "is_active", label: t("All sessions"), value: 1 },
	];

	const [filter, setFilter] = useState(
		amI("Teacher") ? filterTabsTeacher[0] : filterTabs[0],
	);

	/**
	 * sessions table - actions
	 *
	 *  these are the actions allowed depending on the role
	 *  the actions allowed depending on the status of each item
	 *  are defined in SessionsTable
	 */
	const rowActions = useMemo(
		() => [
			...(canI("delete", "SingleTreatmentPlan")
				? [
						{
							name: "delete",
							icon: "ri-delete-bin-line",
							command: handleDelete,
							title: t("Delete session"),
						},
				  ]
				: []),
		],
		[canI, t],
	);

	/*
	if (canI("readAll", "SingleTreatmentPlan"))
		rowActions.push({
			name: "readAll",
			icon: "ri-eye-line",
			command: handleView,
			title: t("View session"),
		});

	if (canI("read", "SingleTreatmentPlan"))
		rowActions.push({
			name: "read",
			icon: "ri-eye-line",
			command: handleView,
			title: t("View session"),
		});

	if (canI("update", "SingleTreatmentPlan"))
		rowActions.push({
			name: "edit",
			icon: "ri-pencil-line",
			command: handleEdit,
			title: t("Edit session"),
		});
	*/

	const columns = useMemo(
		() => [
			/*
			{
				Header: "ID",
				accessor: "id",
				cssClass: "min-cell-width",
			},
			*/
			{
				Header: t("Description"),
				accessor: "description",
				cssClass: "font-medium w-[420px] min-w-[420] truncate",
			},
			...(!amI("Student")
				? [
						{
							Header: t("Author"),
							accessor: "creator",
							cssClass: "whitespace-nowrap",
						},
				  ]
				: []),
			{
				Header: t("Status"),
				accessor: "status",
				cssClass: "w-[140px] min-w-[140px] text-center",
				Cell: ({ cell: { value } }) => (
					<Badge className="badge-status text-xs" value={value} />
				),
			},
			{
				Header: t("Type"),
				accessor: "session_type",
				cssClass: "w-[140px] min-w-[140px] text-center",
				Cell: ({ cell: { value } }) => (
					<Badge className="text-xs" value={value} />
				),
			},
			{
				Header: t("Class ID"),
				accessor: "class_id",
				cssClass: "text-center min-cell-width",
				Cell: ({ cell: { value } }) => (value ? value : "-"),
			},
			{
				Header: t("Class author"),
				accessor: "class_creator",
				cssClass: "min-cell-width",
				Cell: ({ cell: { value } }) => (value ? value : "-"),
			},
			{
				Header: t("Date created"),
				accessor: "created_at",
				cssClass: "text-center min-cell-width",
				Cell: ({ cell: { value } }) => getDate(value),
			},
			{
				Header: t("Grade"),
				accessor: "grade",
				cssClass: "text-center min-cell-width",
				Cell: ({ cell: { value } }) =>
					value ? (
						<Badge className="badge-status text-xs" value={value} />
					) : (
						"-"
					),
			},
			{
				Header: t("Actions"),
				accessor: "accessor",
				cssClass: "actions text-center min-cell-width pr-5",
				disableSortBy: true,
				Cell: ({ row: { original } }) => (
					<Actions row={original} actions={rowActions} readOnly={readOnly} />
				),
			},
		],
		[amI, readOnly, rowActions, t],
	);

	// new session router

	const handleSessionTypeModal = (sessionType) => {
		switch (sessionType) {
			case "new_home_session":
				setShowSessionTypeModal(false);
				setShowSessionNameModal(true);
				break;
			case "new_class_session":
				setShowSessionTypeModal(false);
				setShowClassIDModal(true);
				break;
			default:
		}
	};

	const createHomeSession = async (description) => {
		try {
			const response = await createSession({
				description,
				class_id: null,
			});

			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			} else {
				toast.success(t("Home session created!"));
				setRefreshTable(true);
				navigate(`/sessions/${response.session_id}`);
			}
		} catch (error) {
			toast.error(t("An error occurred"));
			console.error("An error occurred:", error);
		}
	};

	/**
	 * get the class description and use it as the sessions' description
	 * get the stories from the class
	 * start for each story a treatment plan
	 */
	const createClassSession = async (classId) => {
		let classDescription = "";
		let newSessionId = null;
		let stories = [];

		try {
			// step 1: get the class description

			console.log("getting class description");
			const classResponse = await getClass(classId);
			if (classResponse.code === "ERR_BAD_REQUEST") {
				if (classResponse.response.status === 404) {
					toast.error(t("Class with ID {{id}} not found!", { id: classId }));
				} else {
					toast.error(t(classResponse.response.data.detail));
				}
				console.log(classResponse);
				return;
			}
			classDescription = classResponse.description;

			// step 2: create the session

			console.log("creating class session");
			const sessionResponse = await createSession({
				description: classDescription,
				class_id: classId,
			});
			if (sessionResponse.code === "ERR_BAD_REQUEST") {
				toast.error(t(sessionResponse.response.data.detail));
				console.log(sessionResponse);
				return;
			}
			toast.success(t("Class session created!"));
			newSessionId = sessionResponse.session_id;

			// step 3: get the stories from the class

			console.log("getting the stories from the class");
			const storiesResponse = await getStoriesFromClass(classId);
			if (storiesResponse.code === "ERR_BAD_REQUEST") {
				toast.error(t(storiesResponse.response.data.detail));
				console.log(storiesResponse);
				return;
			}
			stories = storiesResponse;

			if (!stories || stories.length === 0) {
				toast.warn(t("This class has no stories"));
				navigate(`/sessions/${newSessionId}`);
				return;
			}

			// step 4: create a treatment plan for each story

			console.log(
				"creating treatment plans for each story in session",
				newSessionId,
			);
			// create treatment plans sequentially to ensure order is maintained
			const treatmentPlanResults = [];
			for (const story of stories) {
				try {
					const response = await createTreatmentPlan({
						sessionId: newSessionId,
						storyId: story?.id,
					});

					treatmentPlanResults.push(response);
				} catch (error) {
					treatmentPlanResults.push(null); // Add null to indicate failure
					toast.error(t("Error creating treatment plan"));
					console.error("Error creating treatment plan", error);
				}
			}

			const successfulPlans = treatmentPlanResults.filter(
				(result) => result !== null,
			).length;

			if (successfulPlans === stories.length) {
				toast.success(`${successfulPlans} ${t("treatment plans created!")}`);
			} else {
				toast.error(
					t(
						"Not all treatment plans were created! Please try making the session again or contact support.",
					),
				);
			}

			navigate(`/sessions/${newSessionId}`);

			console.log("workflow completed successfully", { newSessionId, stories });
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	/**
	 * table - action handlers
	 */

	async function handleView(row) {
		navigate(`/sessions/${row?.id}`);
	}

	async function handleDelete(row) {
		setSession(row);
		setShowDeleteModal(true);
	}

	const applyFilters = (data, filter) => {
		const { key, value } = filter;

		return data.filter((item) => {
			switch (key) {
				case "session_type":
					return item.session_type === value;
				case "status_id":
					return item.status_id === value;
				default:
					return true; // no specific filter matches
			}
		});
	};

	// table - confirmation

	const handleDeleteConfirmed = async () => {
		setShowDeleteModal(false);
		const response = await deleteSession(session.id);
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else {
			toast.success(t("Session deleted!"));
			setRefreshTable(true);
		}
	};

	// fetch data

	useEffect(() => {
		if (!refreshTable) return;

		const fetchSessions = async () => {
			try {
				const response = await getSessions();

				if (response.code === "ERR_BAD_REQUEST") {
					toast.error(t(response.response.data.detail));
					console.log(response);
				} else {
					setSessions(response);
				}
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshTable(false);
			}
		};

		fetchSessions();
	}, [refreshTable, t]);

	return (
		<>
			<div className="view">
				<div className="header">
					<h1>{amI("Student") ? t("My sessions") : t("Sessions")}</h1>
					<HelpTooltip text={helpTooltipText.join("")} />
				</div>

				<div className="content">
					{amI("Student") && (
						<FilterTabs
							className="pt-2 mb-4 border-b"
							filters={filterTabs}
							onChange={(index) => setFilter(filterTabs[index])}
						/>
					)}
					{amI("Teacher") && (
						<FilterTabs
							className="pt-2 mb-4 border-b"
							filters={filterTabsTeacher}
							onChange={(index) => setFilter(filterTabsTeacher[index])}
						/>
					)}
					<SessionsTable
						data={applyFilters(sessions, filter)}
						columns={columns}
						actions={rowActions}
						newAction={
							canI("create", "SingleSession")
								? () => setShowSessionTypeModal(true)
								: null
						}
						newActionLabel={t("Create new session")}
						readOnly={readOnly}
						onRowClick={(row) => handleView(row.original)}
					/>
				</div>
			</div>

			{showSessionTypeModal && (
				<SessionTypeModal
					onClose={() => setShowSessionTypeModal(false)}
					onSubmit={(sessionType) => handleSessionTypeModal(sessionType)}
				/>
			)}

			{showSessionNameModal && (
				<SessionNameModal
					onClose={() => setShowSessionNameModal(false)}
					onSubmit={(description) => createHomeSession(description)}
				/>
			)}

			{showClassIDModal && (
				<ClassIDModal
					onClose={() => setShowClassIDModal(false)}
					onSubmit={(classId) => createClassSession(classId)}
				/>
			)}

			{showDeleteModal && (
				<ConfirmationModal
					title={t("Delete session?")}
					message={t(
						"Are you sure you want to delete the session with the ID {{id}}?\nThis operation cannot be undone.",
						{ id: session.id },
					)}
					yesMessage={t("Delete")}
					destructive={true}
					onClose={() => setShowDeleteModal(false)}
					onSubmit={handleDeleteConfirmed}
				/>
			)}
		</>
	);
};

Sessions.displayName = "Sessions";

export default Sessions;
