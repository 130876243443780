import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import { parse, isValid, format, differenceInYears, isAfter } from "date-fns";

import { getUnits } from "api/unitTypes";
import { getPatient } from "api/patients";

import Modal from "components/Modal/Modal";
import Button, { ButtonGroup } from "components/Form/Button/Button";
import Input from "components/Form/Input/Input";
import InputNumber from "components/Form/Input/InputNumber";
import Select from "components/Form/Select/Select";
import { convertDateFormat } from "utils/utils";

const PatientModal = ({ patientData, onSubmit, ...props }) => {
	const { t } = useTranslation();
	const [patient, setPatient] = useState(
		patientData || {
			first_name: "",
			last_name: "",
			birth_date: "",
			gender: "",
			body_weight: null,
			body_unit_id: null,
			body_unit: null,
			kidney_function: null,
		},
	);
	const [isLoading, setIsLoading] = useState(true);
	const [isEditable, setIsEditable] = useState(true);
	const [history, setHistory] = useState("");
	const [weightUnits, setWeightUnits] = useState([]);
	const [birthDate, setBirthDate] = useState(
		patientData
			? convertDateFormat(patient?.birth_date, "yyyy-MM-dd", "dd-MM-yyyy")
			: "",
	);
	const selectSexOptions = [
		{ value: "Female", label: t("Female") },
		{ value: "Male", label: t("Male") },
	];
	const [sexClass, setSexClass] = useState("");
	const [bodyUnitClass, setBodyUnitClass] = useState("");

	const formatHistoryData = (data) => {
		if (!data || !Array.isArray(data)) {
			console.warn("Expected an array but received:", data);
			return "";
		}

		return data
			.sort((a, b) => a.id - b.id) // sort by item.id in ascending order
			.map((item) => item?.description || "") // extract descriptions
			.filter((desc) => desc) // remove falsy descriptions (e.g., null, undefined, empty string)
			.join(", ");
	};

	const validInputs = () => {
		const parsedDate = parse(birthDate, "dd-MM-yyyy", new Date());

		// strict validation: ensure parsed date matches input and is valid
		if (
			!isValid(parsedDate) ||
			format(parsedDate, "dd-MM-yyyy") !== birthDate
		) {
			toast.error(t("Invalid date. The exepcted format is DD-MM-YYYY."));
			return false;
		}

		// check if the age is in the past
		if (isAfter(parsedDate, new Date())) {
			toast.error(t("Invalid date. Birthdate cannot be in the future."));
			return false;
		}

		//check if the age is realistic (under 130 years old)
		const age = differenceInYears(new Date(), parsedDate);
		if (age > 130 || age < 0) {
			toast.error(t("Invalid date. Age must be under 130 years old."));
			return false;
		}

		if (patient.gender === "") {
			setSexClass("invalid");
			toast.error(t("Please specify the gender."));
			return false;
		}

		return true;
	};

	const validateBirthDate = (target) => {
		if (target.value === "") return;
		if (!isValid(parse(target.value, "dd-MM-yyyy", new Date())))
			target.classList.add("invalid");
		else target.classList.remove("invalid");
	};

	const handleClose = () => {
		setPatient(null);
		props.onClose();
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		if (validInputs()) {
			const parsedItems = history
				.split(",")
				.map((item) => item.trim())
				.filter((item) => item !== "");

			onSubmit({
				...patient,
				patient_history: parsedItems,
				birth_date: convertDateFormat(birthDate, "dd-MM-yyyy", "yyyy-MM-dd"),
			});
			setPatient(null);
			setHistory("");
			setBirthDate("");
			props.onClose();
		}
	};

	/* get the body weight units */

	useEffect(() => {
		const fetchWeightUnits = async () => {
			try {
				const response = await getUnits({ unitTypeId: 1 }); // weight

				if (response === "ERR_BAD_REQUEST") {
					toast.error(t(response.response.response.data));
					console.log(response);
				} else {
					setWeightUnits(
						response.map((item) => {
							return {
								value: item.id,
								label: `${item.description} (${item.code})`,
							};
						}),
					);
				}
			} catch (error) {
				console.error("Error fetching weight units:", error);
			}
		};

		fetchWeightUnits();
	}, [t]);

	/**
	 * if a patient is being edited (patientData is set),
	 * get the patient's history and is_editable flag
	 */

	useEffect(() => {
		if (!patientData) {
			setIsLoading(false);
			return;
		}

		const fetchPatient = async () => {
			setIsLoading(true);
			try {
				const response = await getPatient(patientData.id);

				if (response === "ERR_BAD_REQUEST") {
					toast.error(t(response.response.response.data));
				} else {
					setIsEditable(response.is_editable);
					setHistory(formatHistoryData(response?.patient_history));
				}
			} catch (error) {
				console.error("Error fetching patient history:", error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchPatient();
	}, [patientData, t]);

	return (
		<Modal
			title={patientData ? t("Edit patient") : t("Create new patient")}
			isOpen={props.isOpen}
			onClose={handleClose}
			className="patient-modal"
		>
			<p className="message__note w-96 -mt-4 mb-8">
				{!patientData ? (
					<>
						<i className="ri-book-open-line"></i>
						{t(
							"You are going to add a patient. Changes are possible until the patient has been used in a case.",
						)}
					</>
				) : isEditable ? (
					<>
						<i className="ri-book-open-line"></i>
						{t(
							"You can edit this patient file since it hasn't been used in a case.",
						)}
					</>
				) : (
					<>
						<i className="ri-error-warning-line"></i>
						{t(
							"This patient has been already used in a case.\nYou can't edit the patient data anymore.",
						)}
					</>
				)}
			</p>
			<form className="flex flex-col gap-6" onSubmit={handleSubmit}>
				<Input
					id="first-name"
					label={t("First name")}
					value={patient.first_name}
					autoFocus={true}
					required
					disabled={!isEditable}
					onChange={(e) =>
						setPatient({ ...patient, first_name: e.target.value })
					}
				/>
				<Input
					id="last-name"
					label={t("Last name")}
					value={patient.last_name}
					required
					disabled={!isEditable}
					onChange={(e) =>
						setPatient({ ...patient, last_name: e.target.value })
					}
				/>
				<Input
					id="birth-date"
					label={t("Date of birth")}
					value={birthDate}
					// pattern="[0-9]{2}-[0-9]{2}-(19|20)\d{2}"
					// maxLength="10"
					// minLength="10"
					placeholder={t("DD-MM-YYYY")}
					required
					disabled={!isEditable}
					onChange={(e) => setBirthDate(e.target.value)}
					onBlur={(e) => validateBirthDate(e.target)}
					onFocus={(e) => {
						e.target.classList.remove("invalid");
					}}
				/>
				<Select
					id="sex"
					className={sexClass}
					label={t("Sex")}
					value={selectSexOptions.find((obj) => obj.value === patient.gender)}
					options={selectSexOptions}
					allowNone={false}
					required
					disabled={!isEditable}
					onChange={(value) => {
						setPatient({ ...patient, gender: value.value });
					}}
					onFocus={() => {
						setSexClass("");
					}}
				/>
				<div className="flex gap-5 w-96">
					<InputNumber
						id="body-weight"
						className="flex-1"
						label={t("Body weight")}
						value={patient.body_weight || ""}
						disabled={!isEditable}
						onChange={(value) => setPatient({ ...patient, body_weight: value })}
					/>
					<Select
						id="body-weight-unit-id"
						className={twMerge("flex-1 [&_ul]:max-h-[13em]", bodyUnitClass)}
						label={t("Unit")}
						value={weightUnits.find(
							(obj) => obj.value === patient.body_unit_id,
						)}
						options={weightUnits}
						allowNone={true}
						noneLabel={t("None")}
						disabled={!isEditable}
						onChange={(value) => {
							setPatient({ ...patient, body_unit_id: value.value });
						}}
						onFocus={() => {
							setBodyUnitClass("");
						}}
					/>
				</div>
				<InputNumber
					id="kidney-function"
					label={t("eGFR")}
					value={patient.kidney_function || ""}
					adornment={t("ml/min/1.73m²")}
					disabled={!isEditable}
					onChange={(value) =>
						setPatient({ ...patient, kidney_function: value })
					}
				/>
				<Input
					id="history"
					label={t("Medical history (comma separated)")}
					value={history}
					disabled={!isEditable}
					onChange={(e) => setHistory(e.target.value)}
				/>
				<ButtonGroup>
					<Button onClick={handleClose}>{t("Close")}</Button>

					{!isLoading && isEditable && (
						<Button className="btn-primary">
							{patientData ? t("Save patient") : t("Create patient")}
						</Button>
					)}
				</ButtonGroup>
			</form>
		</Modal>
	);
};

export default PatientModal;
