import React, { createContext, useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

import { updateCurrentUser } from "api/users";
import { permissions } from "./permissions.js";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [userData, _setUserData] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true);

  const setUserData = (userObject) => {
    _setUserData(userObject);
  };

  const clearUserData = () => {
    _setUserData(null);
    localStorage.removeItem("userData");
  };

  const updateUserData = async (updatedUserData) => {
    try {
      const response = await updateCurrentUser({
        firstName: updatedUserData.first_name,
        lastName: updatedUserData.last_name,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        console.error(
          "Error updating user data:",
          response.response.data.detail,
        );
      } else {
        _setUserData((prevUserData) => ({
          ...prevUserData,
          ...updatedUserData,
        }));
      }
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  /**
   * Checks if the user has permission for a specific action on a component.
   * The permissions are hard-coded in the permissions.js file.
   * @param {string} scope - The permission scope (e.g., "view", "edit").
   * @param {string} component - The component name to check permissions for.
   * @returns {boolean} - True if the user has permission, false otherwise.
   */
  const canI = (scope, component) => {
    const role = userData?.user_role;
    const rolePermissions = permissions?.[role];

    if (!rolePermissions) return false;

    const componentPermissions = rolePermissions?.[scope];
    return componentPermissions?.includes(component) || false;
  };

  const canIDelete = (creatorId) => {
    return creatorId === userData?.id || userData?.user_role === "admin";
  };

  const amI = (role) => {
    const userRole = userData?.user_role;
    return userRole === role;
  };

  // Load user data from local storage on mount

  useEffect(() => {
    const savedUserData = JSON.parse(localStorage.getItem("userData"));

    // console.log("loading user data on mount", savedUserData);

    if (savedUserData) {
      _setUserData(savedUserData);
    }

    setIsUserLoading(false);
  }, []);

  // Save user data to local storage whenever it changes

  useEffect(() => {
    if (userData) {
      localStorage.setItem("userData", JSON.stringify(userData));
    } else {
      localStorage.removeItem("userData");
    }
  }, [userData]);

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        updateUserData,
        clearUserData,
        isUserLoading,
        canI,
        canIDelete,
        amI,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const CanIView = ({ component }) => {
  const { canI } = useUser();
  const componentName = component?.type?.displayName || component?.type?.name;

  if (!componentName) {
    console.error(
      "CanIView: Component name not found for permission check",
      componentName,
    );
    return <Navigate to="/" />;
  }

  return canI("view", componentName) ? <>{component}</> : <Navigate to="/" />;
};

const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within an UserProvider");
  }
  return context;
};

export { UserProvider, useUser, CanIView };
