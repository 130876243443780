import { useTranslation } from "react-i18next";

import Badge from "components/Badge/Badge";

import { getDate } from "utils/utils";

const SingleClassHeader = ({ singleClass }) => {
	const { t } = useTranslation();

	return (
		<div className="header ml-8 pb-2 border-b">
			<span className="font-light text-xl">[{t("Class")}]</span>
			<h1 className="truncate">{singleClass.description}</h1>
			<Badge
				className="ms-2 me-8 -mt-[2px] text-xxs"
				value={singleClass.is_published ? "published" : "not published"}
			/>
			{!singleClass.is_active && (
				<Badge className="-mt-[2px] text-xxs" value="archived" />
			)}
			<div className="meta ml-auto -mb-[3px] text-sm self-end flex gap-5 text-gray-500 whitespace-nowrap">
				<div title={t("Class ID")}>
					{t("Class ID")} {singleClass.id}
				</div>
				<div title={t("Submissions reviewed / total")}>
					<i className="ri-file-check-line"></i> {singleClass.reviewed_sessions}
					/{singleClass.total_sessions}
				</div>
				<div title={t("Author")}>
					<i className="ri-account-circle-line"></i> {singleClass.creator}
				</div>
				<div title={t("Date created")}>
					<i className="ri-calendar-line"></i> {getDate(singleClass.created_at)}
				</div>
			</div>
		</div>
	);
};

export default SingleClassHeader;
