import { useTranslation } from "react-i18next";

import Button from "components/Form/Button/Button";
import Table from "components/Table/Table";

const Actions = ({ row, actions = [], readOnly = false }) => {
  const getClassByAction = (row, action, readOnly) => {
    const classList = ["btn-action"];

    switch (action.name) {
      case "readAll":
        break;
      case "read":
        if (row.status === "Draft") classList.push("disabled");
        break;
      case "edit":
        if (row.status !== "Draft") classList.push("disabled");
        break;
      case "review":
        if (row.status !== "Submitted") classList.push("disabled");
        break;
      case "delete":
        if (row.status !== "Draft") classList.push("disabled");
        break;
      default:
    }

    return classList.join(" ");
  };

  return (
    <div className="actions-wrapper whitespace-nowrap flex gap-2">
      {actions.map(function (action, idx) {
        return (
          <Button
            key={idx}
            className={getClassByAction(row, action, readOnly)}
            onClick={() => action.command(row.id)}
            title={action.title}
          >
            <i className={action.icon}></i>
          </Button>
        );
      })}
    </div>
  );
};

const ClassSessionsTable = ({
  data,
  columns,
  actions = [],
  newAction,
  newActionLabel,
  readOnly = false,
  onRowClick,
  showSearch,
}) => {
  const { t } = useTranslation();

  if (actions.length)
    columns.push({
      Header: t("Actions"),
      accessor: "accessor",
      cssClass: "text-center min-cell-width pr-5",
      disableSortBy: true,
      Cell: ({ row: { original } }) => (
        <Actions row={original} actions={actions} readOnly={readOnly} />
      ),
    });

  return (
    <>
      <Table
        columns={columns}
        data={data}
        noDataMessage={t(
          "The list is empty. No sessions were submitted for this class yet.",
        )}
        searchLabel={t("Search by author")}
        searchColumns={["creator"]}
        newAction={readOnly === true ? "" : newAction}
        newActionLabel={newActionLabel}
        onRowClick={onRowClick}
        showSearch={showSearch}
      />
    </>
  );
};

export default ClassSessionsTable;
