import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";
import { validateCode } from "api/auth.js";
import { getCurrentUser } from "api/users";

import Input from "components/Form/Input/Input";
import Password from "components/Form/Input/Password";
import Button from "components/Form/Button/Button";

const isValidInput = () => {
  let isValid = true;

  // validation comes here

  return isValid;
};

/**
 * STEP 2/2 - Validate code input form
 */
const ValidateCode = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const { setAuthData } = useAuth();
  const { userData, setUserData } = useUser();

  console.log(userData);

  // Centralize error handling
  const handleErrorResponse = (errorDetails) => {
    if (typeof errorDetails === "string") {
      setErrorMsg(t(errorDetails));
    } else if (Array.isArray(errorDetails)) {
      setErrorMsg(t(errorDetails[0].msg));
    }
  };

  // Separate function for handling success response
  const handleSuccessResponse = async (response) => {
    if (response.access_token) {
      const user = await getCurrentUser(response.access_token);

      if (!user || user.code === "ERR_BAD_REQUEST") {
        console.error("Error getting current user:", user);
        setAuthData(null);
        setUserData(null);
        navigate("/login");
        throw new Error(user.response.data.detail);
      }

      console.log("response", response);
      console.log("user", user);

      setAuthData(response);
      setUserData(user);

      // Clear sensitive data
      setCode("");
      setPassword("");

      navigate("/dashboard");
    } else {
      console.log("not token found");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validation
    if (!isValidInput()) {
      return;
    }

    try {
      const response = await validateCode({
        email: userData.email,
        code,
        password,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        handleErrorResponse(response.response.data.detail);
      }

      await handleSuccessResponse(response);
    } catch (error) {
      if (error.response) {
        setErrorMsg(t(error.response.data.detail));
      } else {
        setErrorMsg(t("There was an error. Retry or contact the support."));
      }
    }
  };

  return (
    <>
      <form
        className="w-80 p-5 my-5 border rounded shadow flex flex-col gap-5"
        onSubmit={handleSubmit}
      >
        <h2>{t("Input verification code")}</h2>
        <p className="text-sm">
          {t(
            "Type in the verification code you received per E-mail. If the code is valid, your new account will be created.",
          )}
        </p>
        <p className="error">{errorMsg}</p>
        <Input
          id="email"
          className="mt-4"
          label={t("E-mail")}
          type="text"
          value={userData.email}
          required
          readOnly
        />
        <Input
          id="code"
          className="mt-4"
          label={t("Verification code")}
          type="number"
          value={code}
          required
          onChange={(e) => setCode(e.target.value)}
        />
        <Password
          id="password"
          className="mt-4"
          label={t("Re-enter your password")}
          value={password}
          required
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button className="btn-primary my-8">{t("Verify")}</Button>
      </form>
      <Link className="font-sm" to="/login">
        {t("Return to the log in page")}
      </Link>
    </>
  );
};

ValidateCode.displayName = "ValidateCode";

export default ValidateCode;
