import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { DragDropContext } from "@hello-pangea/dnd";

import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import FilterTabs from "components/Tabs/FilterTabs";
import StoriesTable from "components/StoriesTable/StoriesTable";
import StoryModal from "components/StoryModal";
import ConfirmationModal from "components/ConfirmationModal";

import { getStories, createStory, updateStory, deleteStory } from "api/stories";

const Stories = () => {
	const { t } = useTranslation();

	// table - states
	const [stories, setStories] = useState([]);
	const [story, setStory] = useState({});
	const [isDuplicate, setIsDuplicate] = useState(false);
	const [refreshTable, setRefreshTable] = useState(true);

	// modals - states
	const [showStoryModal, setShowStoryModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// filterTabs

	const filterTabs = [
		{ key: "is_active", label: t("Active cases"), value: true },
		{ key: "is_active", label: t("Disabled"), value: false },
	];
	const [filter, setFilter] = useState(filterTabs[0]);

	// table - actions

	const rowActions = useMemo(
		() => [
			{
				name: "edit",
				icon: "ri-pencil-line",
				command: handleEditAction,
				title: t("Edit story"),
			},
			{
				name: "duplicate",
				icon: "ri-file-copy-line",
				command: handleDuplicateAction,
				title: t("Duplicate story"),
			},
			{
				name: "lock",
				icon: "ri-delete-bin-line",
				command: handleDeleteAction,
				title: t("Delete story"),
			},
		],
		[t],
	);

	const applyFilters = (data, filter) => {
		const { key, value } = filter;

		console.log(key, value);

		return data.filter((item) => {
			console.log(item);
			switch (key) {
				case "is_active":
					return item.is_active === value;
				default:
					return true; // no specific filter matches
			}
		});
	};

	function handleEditAction(row) {
		setStory(row);
		setShowStoryModal(true);
	}

	function handleDuplicateAction(row) {
		setStory(row);
		setIsDuplicate(true);
		setShowStoryModal(true);
	}

	function handleDeleteAction(row) {
		setStory(row);
		setShowDeleteModal(true);
	}

	// create a story

	async function handleSubmit(data) {
		const props = {
			title: data.title,
			description: data.description,
			isActive: data.is_active,
			isClassStudy: data.is_class,
			isSelfStudy: data.is_self_study,
			isShared: data.is_shared,
			patientId: data.patient_id,
			categoryId: data.category_id,
			difficultyId: data.difficulty_id,
			courseId: data.course_id,
			sourceId: data.source_id,
		};

		if (story && !isDuplicate) {
			try {
				const response = await updateStory({ storyId: story.id, ...props });

				if (response.code === "ERR_BAD_REQUEST") {
					toast.error(t(response.response.response.data));
				} else {
					toast.success(t("Story updated!"));
					setRefreshTable(true);
				}
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setShowStoryModal(false);
			}
		} else {
			try {
				await createStory(props);
				toast.success(t("Story created!"));
				setRefreshTable(true);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setShowStoryModal(false);
			}
		}
	}

	// delete a story

	const handleDelete = async () => {
		try {
			const response = await deleteStory(story.id);

			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(
					t(
						"This story is linked to one or more classes. Please remove it from those classes before deleting.",
					),
				);
			} else {
				toast.success(t("Story deleted!"));
				setRefreshTable(true);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowDeleteModal(false);
		}
	};

	// get the stories

	useEffect(() => {
		if (!refreshTable) return;

		const fetchStories = async () => {
			try {
				const response = await getStories();

				if (response.code === "ERR_BAD_REQUEST") {
					toast.error(t(response.response.data.detail));
					console.log(response);
				} else {
					setStories(response.data);
				}
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshTable(false);
			}
		};

		fetchStories();
	}, [refreshTable, t]);

	return (
		<>
			<div className="view">
				<div className="header">
					<h1>{t("Stories")}</h1>
					<HelpTooltip
						text={t(
							"List of all the stories. Teachers can add a new story and and use it in classes. Selected stories can also be made available for students doing home sessions. This view is only visible for teachers and admin.",
						)}
					/>
				</div>

				<div className="content">
					<FilterTabs
						className="pt-2 mb-4 border-b"
						filters={filterTabs}
						onChange={(index) => setFilter(filterTabs[index])}
					/>

					<DragDropContext>
						<StoriesTable
							id="all-stories"
							data={applyFilters(stories, filter)}
							actions={rowActions}
							newAction={() => {
								setStory(null);
								setShowStoryModal(true);
							}}
							newActionLabel={t("Create new story")}
							showFilter={true}
							isDragDisabled={true}
							onRowClick={handleEditAction}
						/>
					</DragDropContext>
				</div>
			</div>
			{showStoryModal && (
				<StoryModal
					storyData={story}
					isDuplicate={isDuplicate}
					onClose={() => {
						setStory(null);
						setIsDuplicate(false);
						setShowStoryModal(false);
					}}
					onSubmit={handleSubmit}
				/>
			)}
			{showDeleteModal && (
				<ConfirmationModal
					title={t("Delete story?")}
					message={t(
						"Are you sure you want to delete the story with the ID {{id}}?\nThis operation cannot be undone.",
						{ id: story.id },
					)}
					yesMessage={t("Delete")}
					destructive={true}
					onClose={() => setShowDeleteModal(false)}
					onSubmit={handleDelete}
				/>
			)}{" "}
		</>
	);
};

Stories.displayName = "Stories";

export default Stories;
