import { useRef } from "react";
import { IMaskInput } from "react-imask";
import { twMerge } from "tailwind-merge";

import "./Input.scss";

const InputNumber = ({
  id,
  className = "",
  label,
  value,
  thousandsSeparator = ".",
  radix = ",",
  decimals = 2,
  adornment,
  onChange,
  ...rest
}) => {
  // use ref to get access to internal "masked = ref.current.maskRef"
  const ref = useRef(null);
  const inputRef = useRef(null);

  return (
    <div className={twMerge("input-container w-96", className)}>
      <label htmlFor={id} className={value && "filled"}>
        {label}
        {rest.required && !rest.readOnly ? " *" : ""}
      </label>
      <IMaskInput
        id={id}
        mask={Number}
        thousandsSeparator={thousandsSeparator}
        radix={radix}
        scale={decimals}
        padFractionalZeros={true}
        value={String(value)}
        unmask={true} // true|false|'typed'
        ref={ref}
        inputRef={inputRef} // access to nested input
        disabled={rest.disabled || false}
        // DO NOT USE onChange TO HANDLE CHANGES!
        // USE onAccept INSTEAD
        onAccept={
          // depending on prop above first argument is
          // `value` if `unmask=false`,
          // `unmaskedValue` if `unmask=true`,
          // `typedValue` if `unmask='typed'`
          (value, mask) => {
            onChange(Number(value));
          }
        }
      />
      {adornment && <div className="adornment">{adornment}</div>}
    </div>
  );
};

export default InputNumber;
