import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";
import { validateResetCode } from "api/auth.js";

import Input from "components/Form/Input/Input";
import Button from "components/Form/Button/Button";

const validateCode = () => {
  let isValid = true;

  // validation comes here

  return isValid;
};

/**
 * STEP 2/3 - Reset code input form
 */
const ResetCode = () => {
  const [code, setCode] = useState("");
  const [validCode, setValidCode] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setAuthData } = useAuth();
  const { userData } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validation
    setValidCode(validateCode());

    // authentication
    if (validCode) {
      const response = await validateResetCode({
        email: userData.email,
        code: code,
      });
      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        setAuthData(response);
        setCode("");
        navigate("/reset-password");
      }
    }
  };

  return (
    <>
      <form
        className="w-80 p-5 my-5 border rounded shadow flex flex-col gap-5"
        onSubmit={handleSubmit}
      >
        <h2>{t("Input verification code")}</h2>
        <p className="message__note">
          {t("A verification code was sent to")} {userData.email}.{" "}
          {t("Check your e-mail and enter the code below.")}
        </p>
        <Input
          id="code"
          className="mt-4"
          label={t("Verification code")}
          type="number"
          value={code}
          required
          onChange={(e) => setCode(e.target.value)}
        />
        <Button className="btn-primary my-8">{t("Verify")}</Button>
      </form>
      <p className="font-sm">
        {t("Return to the")} <Link to="/login">{t("Log in")}</Link> {t("page")}
      </p>
    </>
  );
};

ResetCode.displayName = "ResetCode";

export default ResetCode;
