import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Button, { ButtonGroup } from "components/Form/Button/Button";

const InformationModal = ({
	title = "Information",
	message,
	yesMessage = "OK",
	onSubmit,
	onClose,
	...props
}) => {
	const { t } = useTranslation();

	return (
		<Modal title={title} isOpen={props.isOpen} onClose={onClose} {...props}>
			<form className="flex flex-col gap-4">
				{message && <p>{message}</p>}
				{props.children}
				<ButtonGroup>
					<Button className="btn-primary" onClick={onSubmit}>
						{t(yesMessage)}
					</Button>
				</ButtonGroup>
			</form>
		</Modal>
	);
};

export default InformationModal;
