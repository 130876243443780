import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useError } from "context/ErrorContext";

import InformationModal from "components/InformationModal";

const ErrorModal = () => {
  const { t } = useTranslation();
  const { error, hideError } = useError();
  const [isOpen] = useState(false);

  return (
    <>
      {error && (
        <InformationModal
          title={t("Error")}
          message={error}
          isOpen={isOpen}
          onClose={hideError}
          onSubmit={hideError}
        />
      )}
    </>
  );
};

export default ErrorModal;
