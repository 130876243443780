import { useEffect, useState, useRef, useId } from "react";
import ReactDOM from "react-dom";
import { twMerge } from "tailwind-merge";

import "./HelpTooltip.scss";

const Tooltip = ({ text, buttonRef, offsetX = 28, offsetY = 18 }) => {
	const tooltipRef = useRef(null);
	const [style, setStyle] = useState({});

	// calculate tooltip position

	useEffect(() => {
		if (!tooltipRef.current || !buttonRef.current) return;

		const positionTooltip = () => {
			const buttonRect = buttonRef.current.getBoundingClientRect();
			const tooltipRect = tooltipRef.current.getBoundingClientRect();
			const viewportWidth = window.innerWidth;

			// adjust position based on available space
			const position =
				buttonRect.left + tooltipRect.width > viewportWidth
					? {
							top: `${buttonRect.bottom + window.scrollY}px`,
							left: `${buttonRect.right - tooltipRect.width + offsetX}px`,
					  }
					: {
							top: `${buttonRect.bottom + window.scrollY - offsetY}px`,
							left: `${buttonRect.left + window.scrollX + offsetX}px`,
					  };

			// also add opacity to trigger transition
			setStyle({ ...position, opacity: 1 });
		};

		positionTooltip();
	}, [text, buttonRef, offsetX, offsetY]);

	return ReactDOM.createPortal(
		<div
			className={`message__note min-w-[400px] max-w-[600px] absolute z-50 text-left shadow-md pointer-events-none opacity-0 transition-opacity duration-100`}
			style={style}
			ref={tooltipRef}
			role="tooltip"
		>
			<i className="ri-book-open-line"></i>
			<div>
				{(text || "").split("\n").map((line, index) => (
					<p key={index}>{line}</p>
				))}
			</div>
		</div>,
		document.body,
	);
};

const HelpTooltip = ({ className, text, ...props }) => {
	const id = useId();
	const buttonRef = useRef(null);
	const [isHovered, setIsHovered] = useState(false);

	return (
		<>
			<button
				type="button"
				ref={buttonRef}
				className={twMerge(
					"help-wrapper inline-flex items-center text-xl text-gray-400 cursor-help hover:text-gray-500",
					className,
				)}
				tabIndex="0"
				aria-describedby={id}
				{...props}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<i className="ri-question-line"></i>
			</button>
			{isHovered && <Tooltip text={text} buttonRef={buttonRef} />}
		</>
	);
};

export default HelpTooltip;
