import { useTranslation } from "react-i18next";

import Button from "components/Form/Button/Button";

const caseStatus = {
  DRAFT: 1,
  SUBMITTED: 2,
  REVIEWED: 3,
};

const TabHeader = ({
  caseObject,
  session,
  onClick,
  onShowReviewBox,
  onShowDeleteCaseModal,
}) => {
  const { t } = useTranslation();

  return (
    <div className="pb-2 border-b text-sm flex justify-end items-baseline gap-3">
      <div className="meta flex gap-6 text-gray-500">
        <span title={t("Case ID")}>
          {!caseObject?.id
            ? t("Draft")
            : [t("Case ID"), caseObject?.id].join(" ")}
        </span>
      </div>
      <div className="local-actions flex items-center text-gray-500">
        {caseObject?.status_id === caseStatus.DRAFT &&
          session?.session_type !== "Class-Study" && (
            <Button
              className="inline-action danger mr-0 inline-flex gap-1 items-center"
              onClick={onShowDeleteCaseModal}
            >
              <i className="ri-delete-bin-line"></i>
              <span>{t("Delete case")}</span>
            </Button>
          )}
      </div>
    </div>
  );
};

export default TabHeader;
