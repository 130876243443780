import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";

import { getAuthToken } from "api/auth";
import { getCurrentUser } from "api/users";

import Input from "components/Form/Input/Input";
import Password from "components/Form/Input/Password";
import Button from "components/Form/Button/Button";

/**
 * Input validation
 */
const isValidInput = () => {
  let isValid = true;

  // validation comes here

  return isValid;
};

/**
 * Retry a function with exponential backoff.
 *
 * @param {Function} fn - The function to retry (should return a Promise).
 * @param {number} retries - Maximum number of retries.
 * @param {number} delay - Initial delay in milliseconds between retries.
 * @returns {Promise} - Resolves if the function eventually succeeds, or rejects after max retries.
 */
export const retryRequest = async (fn, retries = 3, delay = 1000) => {
  let attempts = 0;

  while (attempts < retries) {
    try {
      return await fn();
    } catch (error) {
      attempts++;
      if (attempts >= retries) {
        throw error;
      }

      console.warn(
        `Retry attempt ${attempts} failed. Retrying in ${delay}ms...`,
      );

      await delayPromise(delay);
      delay *= 2; // exponential backoff
    }
  }
};

// Helper function for delay
const delayPromise = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Login view
 */
const Login = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const { setAuthData, isAuthenticated } = useAuth();
  const { setUserData } = useUser();

  const branch = process.env.REACT_APP_BRANCH;

  console.log(isAuthenticated);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validation
    if (!isValidInput()) {
      setErrorMsg(t("Invalid input. Please check your username and password."));
      return;
    }

    // authentication
    try {
      const response = await retryRequest(
        () => getAuthToken({ username, password }),
        3,
        1000,
      );

      if (!response || response?.code === "ERR_BAD_REQUEST") {
        const errorDetail = response?.response?.data?.detail;
        console.log(response);
        toast.error(
          t(errorDetail || "An error occurred during authentication."),
        );

        if (errorDetail === "User is not confirmed") {
          setUserData({ email: username });
          navigate("/validate-code");
          return;
        }

        throw new Error(errorDetail || "Authentication failed.");
      }

      if (response.access_token) {
        const user = await getCurrentUser(response.access_token);

        if (!user || user.code === "ERR_BAD_REQUEST") {
          console.error("Error getting current user:", user);
          setAuthData(null);
          setUserData(null);
          navigate("/login");
          throw new Error(user.response.data.detail);
        }

        // set auth and user data
        setAuthData(response);
        setUserData(user);

        // clear the input fields
        setUsername("");
        setPassword("");

        navigate("/dashboard");
      }
    } catch (error) {
      const errorMessage = error?.message || t("An unexpected error occurred.");
      setErrorMsg(errorMessage);
      console.error("Login Error:", error); // Add logging for debugging
    }
  };

  return (
    <>
      {false && branch && (
        <div className="relative">
          <div className="px-2 bg-slate-700 text-white font-bold rounded left-1/2 -translate-x-1/2 -translate-y-3 absolute">
            {branch}
          </div>
        </div>
      )}
      <form
        className="w-80 p-5 my-5 border rounded shadow flex flex-col gap-5"
        onSubmit={handleSubmit}
      >
        <h2>{t("Log in")}</h2>
        <p className="message__error">{errorMsg}</p>
        <Input
          id="username"
          className="mt-4"
          label="E-mail"
          type="email"
          value={username}
          required
          onChange={(e) => setUsername(e.target.value)}
        />
        <Password
          id="password"
          className="mt-4"
          label={t("Password")}
          name="password"
          value={password}
          required
          onChange={(e) => setPassword(e.target.value)}
        />
        <Link to="/reset" className="self-end text-sm">
          {t("Forgot password?")}
        </Link>
        <Button className="btn-primary my-8">{t("Log in")}</Button>
      </form>
      <Link className="text-sm" to="/register">
        {t("I don't have an account yet")}
      </Link>
    </>
  );
};

Login.displayName = "Login";

export default Login;
