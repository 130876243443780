import "./Button.scss";
import { twMerge } from "tailwind-merge";

const Button = ({
  className,
  children,
  onClick,
  disabled = false,
  stopPropagation = false,
  ...rest
}) => {
  const handleClick = (event) => {
    if (stopPropagation) {
      event.stopPropagation(); // Stop event from propagating
    }

    if (onClick) {
      event.preventDefault();
      onClick();
    }
  };

  return (
    <button
      className={twMerge("btn", className)}
      onClick={handleClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

const ButtonGroup = ({ className, children, ...props }) => {
  return (
    <div
      className={twMerge("mt-8 flex gap-4 justify-center", className)}
      {...props}
    >
      {children}
    </div>
  );
};

export { Button as default, ButtonGroup };
