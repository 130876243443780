import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Modal from "components/Modal/Modal";
import StoriesTableStatic from "components/StoriesTable/StoriesTableStatic";
import Button from "components/Form/Button/Button";

import { getStories } from "api/stories";

const SelectStoryModal = (props) => {
	const [stories, setStories] = useState([]);
	const [story, setStory] = useState(null);
	const [refreshTable, setRefreshTable] = useState(true);
	const { t } = useTranslation();

	const handleSubmit = () => {
		if (props.onSubmit) {
			props.onSubmit(story);
			props.onClose();
			setStory(null);
		}
	};

	// get all stories

	useEffect(() => {
		if (!refreshTable) return;

		const fetchStories = async () => {
			if (!refreshTable) return;

			try {
				const response = await getStories();

				if (response === "ERR_BAD_REQUEST") {
					toast.error(t(response.response.response.data));
					console.log(response);
				} else {
					// response.data.data = response.data.filter((story) => story.is_active);
					setStories(response.data.filter((story) => story.is_active));
					setRefreshTable(false);
				}
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		fetchStories();
	}, [refreshTable, t]);

	return (
		<Modal
			title={t("Select story")}
			isOpen={props.isOpen}
			onClose={props.onClose}
			onSubmit={props.onSubmit}
			className="select-story-modal w-3/5 h-4/5"
		>
			<div className="h-full max-h-full flex flex-col gap-8">
				{stories.length > 0 && (
					<StoriesTableStatic
						id="available-stories"
						data={stories}
						showFilter={true}
						onRowClick={(row) => setStory(row)}
					/>
				)}
				<Button className="btn-primary self-end" onClick={handleSubmit}>
					{t("Select story")}
				</Button>
			</div>
		</Modal>
	);
};

export default SelectStoryModal;
