const Page404 = () => {
  return (
    <div className="h-screen w-full flex justify-center items-center">
      <h1>Four:oh:four</h1>
    </div>
  );
};

Page404.displayName = "Page404";

export default Page404;
