import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useUser } from "context/UserContext";
import {
  createPatient,
  getPatients,
  updatePatient,
  deletePatient,
} from "api/patients";
import { getDate, addLeadingZeros } from "utils/utils";

import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import FilterTabs from "components/Tabs/FilterTabs";
import Table, { Actions } from "components/Table/Table";
import PatientModal from "components/PatientModal";
import ConfirmationModal from "components/ConfirmationModal";

export default function Patients() {
  // hooks
  const { canI } = useUser();
  const { t } = useTranslation();

  // modals - states
  const [showPatientModal, setShowPatientModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLockModal, setShowLockModal] = useState(false);
  const [showUnlockModal, setShowUnlockModal] = useState(false);

  // table - states
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState(null);
  const [refreshTable, setRefreshTable] = useState(true);

  // table - filters

  const filters = useMemo(
    () => [
      {
        key: "is_active",
        label: t("Active patients"),
        value: 1,
      },
      { key: "is_active", label: t("Locked"), value: 0 },
    ],
    [t],
  );
  const [filter, setFilter] = useState(filters[0]);

  const searchFilter = useCallback((rows, columns, query) => {
    return rows.filter((row) => {
      return [
        addLeadingZeros(row.values["id"], 5) || "",
        row.values["first_name"] || "",
        row.values["last_name"] || "",
      ]
        .join(" ")
        .toLowerCase()
        .includes(query.trim().toLowerCase());
    });
  }, []);

  // table - row actions

  const getRowActions = useCallback(
    (row) => {
      if (!canI("delete", "Patient")) return [];

      return row?.is_active
        ? [
            {
              name: "lock",
              icon: "ri-lock-line",
              command: handleLock,
              title: t("Lock patient file"),
            },
          ]
        : [
            {
              name: "unlock",
              icon: "ri-lock-unlock-line",
              command: handleUnlock,
              title: t("Unlock patient file"),
            },
          ];
    },
    [canI, t],
  );

  // table - columns

  const columns = useMemo(
    () =>
      [
        {
          Header: "ID",
          accessor: "id",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => addLeadingZeros(value, 5),
        },
        {
          Header: "First name",
          accessor: "first_name",
        },
        {
          Header: "Last name",
          accessor: "last_name",
        },
        {
          Header: "Date of birth",
          accessor: "birth_date",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => getDate(value),
        },
        {
          Header: "Age",
          accessor: "age",
          cssClass: "text-center min-cell-width",
        },
        {
          Header: "Age group",
          accessor: "label",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => t(value),
        },
        {
          Header: "Sex",
          accessor: "gender",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => t(value),
        },
        {
          Header: "File status",
          accessor: "is_active",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) =>
            value ? t("active") : t("not active"),
        },
        {
          Header: "Actions",
          accessor: "accessor",
          cssClass: "actions text-center min-cell-width pr-5",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Actions row={original} actions={getRowActions(original)} />
          ),
        },
      ].filter((item) => item !== false),
    [getRowActions, t],
  );

  // table - action handlers

  function handleRowClick(row) {
    setPatient(row.original);
    setShowPatientModal(true);
  }

  function handleLock(row) {
    setPatient(row);
    setShowLockModal(true);
  }

  const lockConfirmed = async () => {
    console.log("Locking patient", patient);
    try {
      const response = await updatePatient({
        id: patient.id,
        firstName: patient.first_name,
        lastName: patient.last_name,
        gender: patient.gender,
        birthDate: patient.birth_date,
        bodyWeight: patient.body_weight,
        bodyUnitId: patient.body_unit_id,
        kidneyFunction: patient.kidney_function,
        is_active: 0,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("Patient locked!"));
        setPatient(null);
        setRefreshTable(true);
      }
    } catch (error) {
      toast.error(t("An error occurred while locking the patient."));
      console.log(error);
    } finally {
      setShowLockModal(false);
    }
  };

  function handleUnlock(row) {
    setPatient(row);
    setShowUnlockModal(true);
  }

  const unlockConfirmed = async () => {
    console.log("unlocking patient", patient);
    try {
      const response = await updatePatient({
        id: patient.id,
        firstName: patient.first_name,
        lastName: patient.last_name,
        gender: patient.gender,
        birthDate: patient.birth_date,
        bodyWeight: patient.body_weight,
        bodyUnitId: patient.body_unit_id,
        kidneyFunction: patient.kidney_function,
        is_active: 1,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("Patient unlocked!"));
        setPatient(null);
        setRefreshTable(true);
      }
    } catch (error) {
      toast.error(t("An error occurred while unlocking the patient."));
      console.log(error);
    } finally {
      setShowUnlockModal(false);
    }
  };

  /*
  function handleDelete(row) {
    setPatient(row);
    setShowDeleteModal(true);
  }
  */

  const deleteConfirmed = async () => {
    try {
      const response = await deletePatient(patient.id);

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("Patient deleted!"));
        setPatient(null);
        setRefreshTable(true);
      }
    } catch (error) {
      toast.error(t("An error occurred while deleting the class."));
      console.log(error);
    } finally {
      setShowDeleteModal(false);
    }
  };

  // modal - handlers

  const handleCreatePatient = async (patientData) => {
    const response = await createPatient({
      firstName: patientData.first_name,
      lastName: patientData.last_name,
      birthDate: patientData.birth_date,
      gender: patientData.gender,
      bodyWeight: patientData?.body_weight,
      bodyUnitId: patientData?.body_unit_id,
      kidneyFunction: patientData?.kidney_function,
      patientHistory: patientData?.patient_history,
    });
    if (response.code === "ERR_BAD_REQUEST") {
      console.log(response);
      toast.error(t(response.response.data.detail));
    } else {
      toast.success(t("Patient created!"));
      setRefreshTable(true);
    }
  };

  const handleUpdatePatient = async (patientData) => {
    const response = await updatePatient({
      id: patientData.id,
      firstName: patientData.first_name,
      lastName: patientData.last_name,
      birthDate: patientData.birth_date,
      gender: patientData.gender,
      bodyWeight: patientData?.body_weight,
      bodyUnitId: patientData?.body_unit_id,
      kidneyFunction: patientData?.kidney_function,
      patientHistory: patientData?.patient_history,
    });
    if (response.code === "ERR_BAD_REQUEST") {
      console.log(response);
      toast.error(t(response.response.data.detail));
    } else {
      toast.success(t("Patient data updated!"));
      setRefreshTable(true);
    }
  };

  const applyFilters = (data, filter) => {
    const { key, value } = filter;

    // only return active elements
    return data.filter((object) => object[key] === value);
  };

  // fetch data

  useEffect(() => {
    if (!refreshTable) return;

    const fetchPatients = async () => {
      try {
        const response = await getPatients();

        if (response.code === "ERR_BAD_REQUEST") {
          toast.error(t(response.response.data.detail));
          console.log(response);
        } else {
          setPatients(response);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setRefreshTable(false);
      }
    };

    fetchPatients();
  }, [filter, refreshTable, t]);

  return (
    <>
      <div className="view">
        <div className="header">
          <h1>{t("Patients")}</h1>
          <HelpTooltip
            text={t(
              "In this view teachers and administrators can manage patients. The students do not have access to this page.",
            )}
          />
        </div>

        <div className="content">
          <FilterTabs
            filters={filters}
            onChange={(index) => setFilter(filters[index])}
          />
          <Table
            data={applyFilters(patients, filter)}
            columns={columns}
            searchLabel={t("Search by ID, first name and/or last name")}
            searchColumns={["id", "first_name", "last_name"]}
            searchFilter={searchFilter}
            searchGlobal={true}
            newAction={() => {
              setPatient(null);
              setShowPatientModal(true);
            }}
            newActionLabel={t("Create new patient")}
            selectedId={patient?.id}
            onRowClick={handleRowClick}
          />
        </div>
      </div>

      {showPatientModal && (
        <PatientModal
          patientData={patient}
          onClose={() => {
            setPatient(null);
            setShowPatientModal(false);
          }}
          onSubmit={(patientData) => {
            if (patient) {
              handleUpdatePatient(patientData);
            } else {
              handleCreatePatient(patientData);
            }
          }}
        />
      )}

      {showDeleteModal && (
        <ConfirmationModal
          title={t("Lock patient?")}
          message=<span>
            {t("Are you sure you want to lock patient {{ name }}?", {
              name: `${patient.first_name} ${patient.last_name}`,
            })}
          </span>
          yesMessage={t("Lock")}
          onClose={() => {
            setPatient(null);
            setShowDeleteModal(false);
          }}
          onSubmit={deleteConfirmed}
        />
      )}

      {showLockModal && (
        <ConfirmationModal
          title={t("Lock patient?")}
          message=<span>
            {t("Are you sure you want to lock patient {{ name }}?", {
              name: `${patient?.first_name} ${patient?.last_name}`,
            })}
          </span>
          yesMessage={t("Lock")}
          onClose={() => {
            setPatient(null);
            setShowLockModal(false);
          }}
          onSubmit={lockConfirmed}
        />
      )}

      {showUnlockModal && (
        <ConfirmationModal
          title={t("Unlock patient?")}
          message=<span>
            {t("Are you sure you want to unlock patient {{ name }}?", {
              name: `${patient?.first_name} ${patient?.last_name}`,
            })}
          </span>
          yesMessage={t("Unlock")}
          onClose={() => {
            setPatient(null);
            setShowUnlockModal(false);
          }}
          onSubmit={unlockConfirmed}
        />
      )}
    </>
  );
}

Patients.displayName = "Patients";
