import { useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import { InlineSpinner } from "components/Spinner/Spinner";
import { OverviewTab, OverviewPanel } from "components/Tabs/OverviewTab";
import TabHeader from "components/Tabs/TabHeader";
import StoryWidget from "components/StoryWidget/StoryWidget";
import PatientWidget from "components/PatientWidget/PatientWidget";
import PrescriptionsWidget from "components/PrescriptionsWidget/PrescriptionsWidget";

const CaseTabs = ({
  className = "",
  cases = [],
  session = null,
  selectedIndex,
  onAddTab = null,
  onRemoveTab = null,
  onChange = null,
  onShowReviewBox = null,
  onShowDeleteCaseModal = null,
  onOpenStoryModal = null,
  onOpenPatientModal = null,
}) => {
  const { t } = useTranslation();
  const [canAddTab, setCanAddTab] = useState();

  const isLoading = cases === null;

  const allowAddTab =
    session?.session_type === "Self-Study" && session?.status === "Draft";

  useEffect(() => {
    // set canAddTab to true if cases is empty or if the last case has the id 0
    setCanAddTab(cases.length === 0 || cases[cases.length - 1]?.id === 0);
  }, [cases]);

  const handleAddTab = () => {
    setCanAddTab(true);
    onAddTab && onAddTab();
  };

  const handleRemoveTab = () => {
    setCanAddTab(false);
    onRemoveTab && onRemoveTab();
  };

  return (
    <Tab.Group
      as="div"
      className="tabs-wrapper min-w-[720px] h-full w-full max-w-screen-xl overflow-auto flex flex-col"
      selectedIndex={selectedIndex}
      onChange={onChange}
    >
      <Tab.List className={twMerge("ml-2 flex gap-1", className)}>
        <OverviewTab selected={(selected) => true} />
        {cases.map((caseItem, index) => {
          const firstName = caseItem?.patient?.first_name ?? "";
          const lastName = caseItem?.patient?.last_name ?? "";
          const tabTitle =
            caseItem?.story?.title ?? `${firstName} ${lastName}`.trim();

          return (
            <Tab
              key={index}
              className={({ selected }) =>
                twMerge(
                  "max-w-56 py-1 px-2 -mb-[1px] mt-[1px] text-sm font-medium border border-gray-400 rounded-t focus-visible:outline outline-2 focus-visible:outline-primary outline-offset-1 truncate",
                  selected
                    ? " border-b-4 border-b-primary text-primary bg-gray-100"
                    : " border-b-0 border-b-transparent text-gray-500",
                )
              }
              title={tabTitle}
            >
              {tabTitle || t("New case")}
            </Tab>
          );
        })}

        {!isLoading && cases.length === 0 && (
          <Tab
            className={({ selected }) =>
              twMerge(
                "max-w-56 py-1 px-2 -mb-[1px] mt-[1px] text-sm font-medium border border-gray-400 rounded-t focus-visible:outline outline-2 focus-visible:outline-primary outline-offset-1 truncate",
                selected
                  ? " border-b-4 border-b-primary text-primary bg-gray-100"
                  : " border-b-0 border-b-transparent text-gray-500",
              )
            }
          >
            <span className="flex justify-center items-center">
              {t("No cases started yet")}
            </span>
          </Tab>
        )}

        {isLoading && (
          <Tab className="py-1 px-2 -mb-[1px] mt-[1px] text-sm font-medium border border-gray-400 rounded-t focus-visible:outline outline-2 focus-visible:outline-primary outline-offset-1 text-gray-500 border-b-4 border-b-transparent flex justify-center items-center">
            <span className="flex justify-center items-center">
              &nbsp;
              <InlineSpinner isLoading={true} />
              &nbsp;
            </span>
          </Tab>
        )}

        {allowAddTab && !canAddTab && cases.length > 0 && (
          <button
            className={twMerge(
              "btn-action -ms-1 text-primary font-bold",
              canAddTab ? "disabled" : "",
            )}
            title={t("Add new case")}
            onClick={handleAddTab}
            disabled={canAddTab}
          >
            <i className="ri-add-line"></i>
          </button>
        )}

        {allowAddTab && (canAddTab || cases.length === 0) && (
          <button
            className="btn-action -ms-1 text-primary disabled:text-gray-500 font-bold"
            disabled={cases.length === 0}
            onClick={handleRemoveTab}
            title={
              cases.length === 1
                ? t("At least one tab is required")
                : t("Remove draft")
            }
          >
            <i className="ri-close-line"></i>
          </button>
        )}
      </Tab.List>
      <Tab.Panels className="h-full overflow-y-auto border border-gray-400 rounded">
        <OverviewPanel cases={cases} onClick={onChange} />
        {cases.map((caseItem, index) => (
          <Tab.Panel key={index} className="p-4 flex flex-col gap-8">
            <TabHeader
              caseObject={caseItem}
              session={session}
              onShowReviewBox={onShowReviewBox}
              onShowDeleteCaseModal={() => onShowDeleteCaseModal(caseItem?.id)}
            />
            <div className="-mt-4">
              <h4 className="mb-1 font-semibold">{t("Story")}</h4>
              <StoryWidget
                storyId={caseItem?.story?.id}
                readOnly={
                  session?.session_type === "Class-Study" ||
                  (session?.session_type === "Self-Study" &&
                    session.status !== "Draft")
                }
                onSubmit={onOpenStoryModal}
              />
            </div>
            <div>
              <h4 className="mb-1 font-semibold">{t("Patient")}</h4>
              <PatientWidget
                patientId={caseItem?.patient?.id}
                readOnly={session.status !== "Draft"}
                onSubmit={onOpenPatientModal}
              />
            </div>
            <div>
              <h4 className="font-semibold">{t("List of prescriptions")}</h4>
              <PrescriptionsWidget
                caseId={caseItem.id}
                storyId={caseItem?.story?.id}
                patientId={caseItem?.patient?.id}
                readOnly={caseItem?.id === 0 || session.status !== "Draft"}
              />
            </div>
          </Tab.Panel>
        ))}

        {cases.length === 0 && (
          <Tab.Panel className="p-4 flex flex-col gap-6">
            <TabHeader id={0} />
            <div>
              <h3 className="mb-2">{t("Story")}</h3>
              <StoryWidget onSubmit={onOpenStoryModal} />
            </div>
            <div>
              <h3 className="mb-2">{t("Patient")}</h3>
              <PatientWidget patientId={0} onSubmit={onOpenPatientModal} />
            </div>
            <div>
              <h3 className="mb-2">
                {t("List of prescriptions")}
                {cases.length}
              </h3>
              <PrescriptionsWidget caseId={0} readOnly={true} />
            </div>
          </Tab.Panel>
        )}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default CaseTabs;
