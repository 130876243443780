import { useState } from "react";
import { twMerge } from "tailwind-merge";

import "./Input.scss";

const Password = ({ id, className = "", label, value, onChange, ...rest }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className={twMerge("input-container max-w-96 password", className)}>
      <label htmlFor={id} className={value && "filled"}>
        {label}
        {rest.required ? " *" : ""}
      </label>
      <input
        type={passwordVisible ? "text" : "password"}
        id={id}
        value={value}
        onChange={onChange}
        {...rest}
      />
      <button
        type="button"
        className="password-visibility-toggler"
        onClick={togglePasswordVisibility}
      >
        {passwordVisible ? (
          <i className="ri-eye-off-line"></i>
        ) : (
          <i className="ri-eye-line"></i>
        )}
      </button>
    </div>
  );
};

export default Password;
