import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { twMerge } from "tailwind-merge";

import Button from "components/Form/Button/Button";

const Backdrop = () => {
  return (
    <div className="backdrop w-full h-full fixed top-0 left-0 z-50 bg-black opacity-30" />
  );
};

function ModalWindow({ title, className, onClose, children }) {
  useEffect(() => {
    const closeOnEscapeKey = (e) => (e.key === "Escape" ? onClose() : null);

    document.body.addEventListener("keydown", closeOnEscapeKey);

    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [onClose]);

  return (
    <div
      className={twMerge(
        "modal w-auto max-w-[95%] max-h-[90%] fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-50 flex flex-col bg-white overflow-hidden shadow-2xl",
        className,
      )}
    >
      <div className="modal-titlebar py-3 px-4 border-b flex relative">
        <h2 className="m-0 leading-none">{title}</h2>
        <Button className="btn-close" onClick={onClose}>
          <i className="ri-close-line"></i>
        </Button>
      </div>
      <div className="modal-content h-full pt-10 p-6 overflow-hidden">
        {children}
      </div>
    </div>
  );
}

const Modal = (props) => {
  const portalElement = document.getElementById("overlays");

  return (
    <>
      {ReactDOM.createPortal(<Backdrop {...props} />, portalElement)}
      {ReactDOM.createPortal(
        <ModalWindow {...props}>{props.children}</ModalWindow>,
        portalElement,
      )}
    </>
  );
};

export default Modal;
