import React from "react";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useHelp } from "context/HelpContext";

import Button from "components/Form/Button/Button";

import "./HelpSidebar.scss";

function flatten(children) {
	return children.reduce((text, child) => {
		return typeof child === "string"
			? text + child
			: text + flatten(React.Children.toArray(child.props.children));
	}, "");
}

/**
 * custom heading renderer
 *
 * add id to heading based on the heading text
 */
const headingRenderer = ({ children, node }) => {
	const text = flatten(React.Children.toArray(children));
	const slug = text.toLowerCase().replace(/\W/g, "-");
	const TagName = node.tagName;

	return <TagName id={slug}>{children}</TagName>;
};

/**
 * custom anchor renderer
 *
 * use for external links and internal anchors only <a>
 * use for internal links <Link>
 */
function anchorRenderer(props) {
	return props.href.match(/^(https?:)?\/\//) || props.href.match(/^#/) ? (
		<a href={props.href}>{props.children}</a>
	) : (
		<Link to={props.href}>
			{props.children} <i className="ri-external-link-line"></i>
		</Link>
	);
}

const HelpSidebar = ({ onClose }) => {
	const { topic, content } = useHelp();
	const { t } = useTranslation();

	return (
		<div className="help-sidebar ms-auto shrink-0 w-[320px] flex flex-col overflow-hidden border-l text-slate-700">
			<div className="py-3 px-4 flex gap-2 items-center relative border-b">
				<h2 className="m-0 text-sm leading-none">{t("Help")}</h2>
				<Button className="btn-close" onClick={onClose}>
					<i className="ri-close-line text-2xl"></i>
				</Button>
			</div>
			<div className="content px-4 overflow-auto scroll-smooth shadow-inner">
				<h3
					id="top-heading"
					className="px-4 py-2 mb-4 -mx-4 bg-slate-100 text-sm font-normal uppercase text-slate-500"
				>
					{t(topic)}
				</h3>
				<Markdown components={{ h2: headingRenderer, a: anchorRenderer }}>
					{content}
				</Markdown>
				<div className="m-8 text-center">
					<a href="#top-heading" className="btn-back-to-top">
						<i className="ri-arrow-up-s-line"></i>
						{t("Back to top")}
					</a>
				</div>
			</div>
		</div>
	);
};

export default HelpSidebar;
