import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Button, { ButtonGroup } from "components/Form/Button/Button";

// import { AuthContext } from "./../../context/AuthContext";

const ConfirmationModal = ({
	title = "Confirmation",
	message,
	yesMessage = "OK",
	destructive = false,
	onSubmit,
	onClose,
	...props
}) => {
	const { t /*, i18n*/ } = useTranslation();

	return (
		<Modal title={title} isOpen={props.isOpen} onClose={onClose} {...props}>
			<form className="flex flex-col gap-4">
				<p className="whitespace-pre-line">{message}</p>
				<ButtonGroup>
					<Button onClick={onClose}>{t("Cancel")}</Button>
					<Button
						className={destructive ? "btn-delete" : "btn-primary"}
						onClick={onSubmit}
					>
						{t(yesMessage)}
					</Button>
				</ButtonGroup>
			</form>
		</Modal>
	);
};

export default ConfirmationModal;
