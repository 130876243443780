import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";
import { setPassword } from "api/auth";
import { getCurrentUser } from "api/users";

import Password from "components/Form/Input/Password";
import Button from "components/Form/Button/Button";

const validateCode = () => {
  let isValid = true;

  // validation comes here

  return isValid;
};

/**
 * STEP 3/3 - Reset password input form
 */
const ResetPassword = () => {
  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");
  const [validCode, setValidCode] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { authData, setAuthData } = useAuth();
  const { userData, setUserData } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validation
    setValidCode(validateCode());

    if (!validCode) return;

    try {
      const response = await setPassword({
        email: userData.email,
        token: authData.reset_password_token,
        password: pwd1,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        const user = await getCurrentUser(response.access_token);

        setAuthData(response);
        setUserData(user);

        setPwd1("");
        setPwd2("");

        navigate("/dashboard");
      }
    } catch (error) {
      toast.error(t("There was an error. Retry or contact the support."));
      console.log(error);
    }
  };

  return (
    <>
      <form
        className="w-80 p-5 my-5 border rounded shadow flex flex-col gap-5"
        onSubmit={handleSubmit}
      >
        <h2>{t("New password")}</h2>
        <p>{t("Enter a new password")}</p>
        <Password
          id="password1"
          className="mt-4"
          label={t("New password")}
          value={pwd1}
          required
          onChange={(e) => setPwd1(e.target.value)}
        />
        <Password
          id="password2"
          className="mt-4"
          label={t("Re-type the new password")}
          value={pwd2}
          required
          onChange={(e) => setPwd2(e.target.value)}
        />
        <Button className="btn-primary my-8">{t("Verify")}</Button>
      </form>
      <p className="text-sm">
        {t("Return to the")} <Link to="/login">{t("Log in")}</Link> {t("page")}
      </p>
    </>
  );
};

export default ResetPassword;
