import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useUser } from "context/UserContext";

const HelpContext = createContext();

const HelpProvider = ({ children }) => {
  const [topic, setTopic] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { i18n } = useTranslation();
  const { userData } = useUser();

  const routeHelpTopics = useMemo(
    () => [
      // static routes
      { match: /^\/dashboard$/, topic: "Dashboard" },
      { match: /^\/classes$/, topic: "Classes" },
      { match: /^\/stories$/, topic: "Stories" },
      { match: /^\/patients$/, topic: "Patients" },
      { match: /^\/sessions$/, topic: "Sessions" },
      { match: /^\/profile$/, topic: "Profile" },

      // dynamic routes - removing everything after
      { match: /^\/classes\/\d+$/, topic: "Class" },
      { match: /^\/sessions\/\d+$/, topic: "Session" },

      // generic fallback - removing leading slash and everything after
      { match: /.*/, topic: (path) => path.replace(/^\/|\/.*$/g, "") },
    ],
    [],
  );

  const setHelpTopic = (helpTopic) => {
    if (helpTopic === topic) return;
    setTopic(helpTopic);
  };

  const showHelp = async () => {
    setIsVisible(true);
    if (topic) {
      setIsLoading(true);
    }
  };

  const hideHelp = () => {
    setIsVisible(false);
  };

  const fetchFile = useCallback(async (path) => {
    try {
      setIsLoading(true);

      const response = await fetch(path);

      // will be skipped probably all the time since the router will return an html
      if (!response.ok) {
        throw new Error(`Could not load help file: ${path}`);
      }

      const content = await response.text();

      // if content starts with <!doctype html> then there is no help content available
      return content.startsWith("<!doctype html>") ? null : content;
    } catch (error) {
      console.error("Error loading help content:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // set topic based on location

  useEffect(() => {
    const currentPath = location.pathname;
    const matchedRoute = routeHelpTopics.find((route) =>
      route.match.test(currentPath),
    );

    // handle both string topics and dynamic ones defined as functions
    const topic =
      matchedRoute && typeof matchedRoute.topic === "function"
        ? matchedRoute.topic(currentPath)
        : matchedRoute?.topic || "default";

    setTopic(topic);
  }, [location.pathname, routeHelpTopics]);

  // fetch the help content based on the topic, language and user role

  useEffect(() => {
    const fetchContent = async () => {
      const lang = i18n.language;
      const userRole = userData ? userData.user_role : "default";

      try {
        let content = "";
        content = await fetchFile(
          `${process.env.REACT_APP_PUBLIC_URL}/help/${lang}/${topic}/${userRole}.md`,
        );

        // fallback to default content if user role specific content is not available
        if (!content)
          content = await fetchFile(
            `${process.env.REACT_APP_PUBLIC_URL}/help/${lang}/${topic}/default.md`,
          );

        // fallback to default content if topic specific content is not available
        if (!content)
          content = await fetchFile(
            `${process.env.REACT_APP_PUBLIC_URL}/help/${lang}/default.md`,
          );

        setContent(content || "");
      } catch (error) {
        console.error("Error loading help content:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchContent();
  }, [i18n.language, topic, userData, fetchFile]);

  return (
    <HelpContext.Provider
      value={{
        topic,
        setHelpTopic,
        content,
        isVisible,
        showHelp,
        hideHelp,
        isLoading,
        setContent,
      }}
    >
      {children}
    </HelpContext.Provider>
  );
};

const useHelp = () => {
  const context = useContext(HelpContext);

  if (!context) {
    throw new Error("useHelp must be used within a HelpProvider");
  }

  return useContext(HelpContext);
};

export { HelpProvider, useHelp };
