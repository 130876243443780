import { useEffect, useRef, useState } from "react";
import { Listbox } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import { usePopper } from "react-popper";

import "./Select.scss";

const Select = ({
  id,
  className = "",
  label,
  options,
  value,
  focused = false,
  allowNone = true,
  noneLabel = "None",
  onChange,
  onBlur = null,
  onFocus = null,
  readOnly,
  required = false,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(inputRef.current, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 6], // [horizontal offset, vertical offset]
        },
      },
    ],
  });

  const classByValue =
    "select-value__" + String(value?.value).toLowerCase().replace(" ", "-");

  useEffect(() => {
    if (focused && inputRef) {
      inputRef.current.focus();
    }
  }, [focused]);

  return (
    <div
      id={id}
      className={twMerge("select-container max-w-96", classByValue, className)}
      readOnly={readOnly}
    >
      <label htmlFor={id} className={value && "filled"}>
        {label}
        {required && " *"}
      </label>
      <Listbox
        defaultValue={value}
        disabled={rest.disabled || false}
        onChange={onChange}
        /**
         * since Listbix is a Fragment, as="div" is needed for the implementation of onBlur
         * onBlur fires twice, once for the button and once for the list
         * the list is what is needed
         */
        as="div"
        onBlur={(e) => {
          if (e.target.tagName.toLowerCase() === "ul" && onBlur) onBlur(e);
        }}
        onFocus={(e) => {
          if (onFocus) onFocus();
        }}
      >
        <Listbox.Button className="btn-select flex gap-4" ref={inputRef}>
          <div>{value?.label}</div>
          <div className="ml-auto">
            <i className="ri-arrow-down-s-line"></i>
          </div>
        </Listbox.Button>
        <Listbox.Options
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {allowNone && (
            <Listbox.Option key={-1} value={""} className="flex">
              <span className="mr-auto">
                <i>{noneLabel}</i>
              </span>
              {(value === "" || value === null) && (
                <i className="ri-check-line ml-auto"></i>
              )}
            </Listbox.Option>
          )}
          {options.map((option, idx) => (
            <Listbox.Option key={idx} value={option} className="flex">
              <span className="mr-auto">{option.label}</span>
              {value?.value === option.value && (
                <i className="ri-check-line ml-auto"></i>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default Select;
