import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Modal from "components/Modal/Modal";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import ReviewForm from "components/ReviewForm/ReviewForm";
import StoryWidget from "components/StoryWidget/StoryWidget";
import PatientWidget from "components/PatientWidget/PatientWidget";
import Button, { ButtonGroup } from "components/Form/Button/Button";
import Combo from "components/Form/Combo/Combo";
import Input from "components/Form/Input/Input";
import Textarea from "components/Form/Textarea/Textarea";

import { getPrescription } from "api/prescriptions";
import { searchDrug } from "api/drugs";
import { getDoseUnits } from "api/doseUnit";
import { getDoseFreqs } from "api/doseFreq";
import { getROAs } from "api/roa";
import { reviewPrescription } from "api/reviews";

import { useError } from "context/ErrorContext";
import { useUser } from "context/UserContext";

const caseStatus = {
	DRAFT: 1,
	SUBMITTED: 2,
	REVIEWED: 3,
};

const PrescriptionModal = ({
	sessionId,
	caseId,
	storyId,
	patientId,
	prescriptionId,
	isOpen,
	onClose,
	onSubmit,
	onSubmitReview = null,
	...props
}) => {
	const { t } = useTranslation();

	const { showError } = useError();
	const { userData, amI } = useUser();

	const [searchQuery, setSearchQuery] = useState("");

	const [prescription, setPrescription] = useState(null);
	const [refreshPrescription, setRefreshPrescription] = useState(true);
	const [showReviewForm, setShowReviewForm] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const [dose, setDose] = useState(prescription?.dose || "");
	const [drugs, setDrugs] = useState([]);
	const [drug, setDrug] = useState(
		prescription
			? { value: prescription.drug_id, label: prescription.drug_name }
			: null,
	);
	const [units, setUnits] = useState([]);
	const [unit, setUnit] = useState(
		prescription
			? {
					value: prescription.dose_unit_id,
					label: prescription.dose_unit_description,
			  }
			: null,
	);
	const [freqs, setFreqs] = useState([]);
	const [freq, setFreq] = useState(
		prescription
			? {
					value: prescription.dose_frequency_id,
					label: prescription.dose_frequency,
			  }
			: null,
	);
	const [routes, setRoutes] = useState([]);
	const [route, setRoute] = useState(
		prescription
			? {
					value: prescription.route_of_administration_id,
					label: prescription.route_of_administration,
			  }
			: null,
	);
	const [freqNumber, setFreqNumber] = useState(
		prescription?.dose_frequency_number || "",
	);
	const [notes, setNotes] = useState(prescription?.notes || "");
	const [startDate, setStartDate] = useState(prescription?.start_date || "");
	const [endDate, setEndDate] = useState(prescription?.end_date || "");
	const [annotation, setAnnotation] = useState(prescription?.annotation || "");

	const formatDateForInput = (timestamp) => {
		if (!timestamp) return "";
		return timestamp.split("T")[0];
	};

	const formatDateForSubmit = (date) => (date ? `${date} 00:00:00` : null);

	const resetForm = () => {
		setDose("");
		setDrug(null);
		setUnit(null);
		setFreq(null);
		setFreqNumber("");
		setRoute(null);
		setNotes("");
		setStartDate("");
		setEndDate("");
		setAnnotation("");
	};

	const isFormValid = () => {
		return drug;
	};

	const handleSubmitReviewForm = async ({ grade, notes }) => {
		try {
			const response = await reviewPrescription({
				id: prescriptionId,
				gradeId: grade.value,
				notes: notes,
			});

			if (response === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.response.data));
			} else {
				toast.success(t("Prescription review saved!"));
				onSubmitReview && onSubmitReview();
			}
		} catch (error) {
			const errorMessage = error.response?.data?.detail || "An error occurred";
			toast.error(t(errorMessage));
		}
	};

	const handleSubmit = () => {
		if (!isFormValid()) {
			toast.error(t("Fill out all required fields!"));
			return;
		}

		// the destination differs if editing or creating new
		const destination = prescription
			? { prescriptionId: prescription.id }
			: { caseId: caseId };

		onSubmit({
			...destination,
			drugId: drug.value,
			ROAId: route?.value || undefined,
			doseUnitId: unit?.value || undefined,
			doseFreqId: freq?.value || undefined,
			dose: dose || undefined,
			doseFreqNumber: freqNumber || undefined,
			notes: notes || undefined,
			startDate: formatDateForSubmit(startDate) || undefined,
			endDate: formatDateForSubmit(endDate) || undefined,
			annotation: annotation || undefined,
		});
		resetForm();
		onClose();
	};

	const getModalTitle = (prescription) => {
		if (isLoading) return "\u00a0"; // no-break space as placeholder

		if (!prescription) return t("Add prescription");

		switch (prescription.status_id) {
			case caseStatus.DRAFT:
				return t("Edit prescription");
			case caseStatus.SUBMITTED:
			case caseStatus.REVIEWED:
				return amI("Teacher")
					? t("Review prescription")
					: t("View prescription");
			default:
				return t("Prescription");
		}
	};

	useEffect(() => {
		const _showReviewForm = () => {
			if (prescription === null) return false;
			if (amI("Teacher") && prescription.status_id !== caseStatus.DRAFT)
				return true;
			if (amI("Student") && prescription.status_id === caseStatus.REVIEWED)
				return true;
		};

		setShowReviewForm(_showReviewForm());
	}, [prescription, amI]);

	/* get the prescription */

	useEffect(() => {
		if (!refreshPrescription || !prescriptionId) {
			setIsLoading(false);
			return;
		}

		const fetchPrescription = async () => {
			try {
				setIsLoading(true);
				const data = await getPrescription(prescriptionId);

				if (data === "ERR_BAD_REQUEST") {
					toast.error(t(data.response.response.data));
					console.log(data);
				} else {
					setPrescription(data);
					setDrug({ value: data.drug_id, label: data.drug_name });
					setFreqNumber(data.dose_frequency_number || "");
					setFreq(
						data.dose_frequency_id && data.dose_frequency
							? {
									value: data.dose_frequency_id,
									label: data.dose_frequency,
							  }
							: null,
					);
					setDose(data.dose || "");
					setUnit(
						data.dose_unit_id && data.dose_unit_description
							? {
									value: data.dose_unit_id,
									label: data.dose_unit_description,
							  }
							: null,
					);
					setRoute(
						data.route_of_administration_id && data.route_of_administration
							? {
									value: data.route_of_administration_id,
									label: data.route_of_administration,
							  }
							: null,
					);
					setNotes(data.notes || "");
					setStartDate(formatDateForInput(data.start_date));
					setEndDate(formatDateForInput(data.end_date));
					setAnnotation(data.annotation || "");
				}
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshPrescription(false);
				setIsLoading(false);
			}
		};

		fetchPrescription();
	}, [prescriptionId, showError, refreshPrescription, t]);

	// populating the drug list

	useEffect(() => {
		(async () => {
			const data = await searchDrug(searchQuery ? searchQuery : "a");
			if (data === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.response.data));
				console.log(data);
			} else {
				if (Array.isArray(data))
					setDrugs(
						data.map((item) => ({
							value: item.id,
							label: item.name,
						})),
					);
				else setDrugs([]);
			}
		})();
	}, [searchQuery, showError, t]);

	// populating the dose frequency list

	useEffect(() => {
		(async () => {
			const data = await getDoseFreqs();
			if (data === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.response.data));
				console.log(data);
			} else {
				setFreqs(
					data.map((item) => ({
						value: item.id,
						label: `${item.description} ${
							item.code ? `(${item.code})` : ""
						}`.trim(),
					})),
				);
			}
		})();
	}, [t]);

	// populating the dose unit list

	useEffect(() => {
		(async () => {
			const data = await getDoseUnits();
			if (data === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.response.data));
				console.log(data);
			} else {
				setUnits(
					data.map((item) => {
						return {
							value: item.id,
							label: `${item.description} (${item.code})`,
						};
					}),
				);
			}
		})();
	}, [t]);

	// populating the route of administration list

	useEffect(() => {
		(async () => {
			const data = await getROAs();
			if (data === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.response.data));
				console.log(data);
			} else {
				setRoutes(
					data.map((item) => ({
						value: item.id,
						label: item.description,
					})),
				);
			}
		})();
	}, [t]);

	return (
		<Modal
			title={getModalTitle(prescription)}
			isOpen={isOpen}
			onClose={onClose}
			onSubmit={onSubmit}
			className="prescription-modal w-[1200px] h-[800px] max-h-[90vh]"
		>
			<div className="-mt-6 h-[calc(100%+1.5rem)] w-full flex gap-8">
				<div className="w-3/4 h-full p-1 pe-3 flex flex-col gap-8 overflow-auto">
					<div>
						<div className="flex gap-2">
							<h4 className="mb-1 font-semibold">{t("Story")}</h4>
							{!storyId && (
								<HelpTooltip
									className="-mt-1"
									text={t(
										"You can prescribe even without a story.\nIf you want to select a story, close this modal and select one.",
									)}
								/>
							)}
						</div>
						<StoryWidget storyId={storyId} readOnly={true} />
					</div>
					<div>
						<div className="flex gap-2">
							<h4 className="mb-1 font-semibold">{t("Patient")}</h4>
							{!patientId && (
								<HelpTooltip
									className="-mt-1"
									text={t(
										"You can prescribe even without a patient file.\nIf you want to select a patient, close this modal and select one in the case.",
									)}
								/>
							)}
						</div>
						<PatientWidget patientId={patientId} readOnly={true} />
					</div>
					<form className="w-full mt-4 flex flex-col gap-6">
						<Combo
							id="drug"
							label={t("Product")}
							options={drugs}
							value={drug}
							onChange={setDrug}
							query={searchQuery}
							setQuery={setSearchQuery}
							autoFocus={true}
							readOnly={
								prescription && prescription.status_id !== caseStatus.DRAFT
							}
							required
						/>
						{/*error === "drug" ? (
									<span>The product is a requierd field</span>
								) : (
									""
								)*/}
						<div className="flex max-w-full gap-2">
							<Input
								id="freqNumber"
								className="w-40"
								label={t("Frequency")}
								value={freqNumber}
								onChange={(e) => setFreqNumber(e.target.value)}
								readOnly={
									prescription && prescription.status_id !== caseStatus.DRAFT
								}
							/>
							<Combo
								id="freq"
								className="mr-6"
								label={t("Time unit")}
								options={freqs}
								value={freq}
								onChange={setFreq}
								readOnly={
									prescription && prescription.status_id !== caseStatus.DRAFT
								}
							/>
							<Input
								id="dose"
								className="w-40"
								label={t("Dose")}
								value={dose}
								onChange={(e) => setDose(e.target.value)}
								readOnly={
									prescription && prescription.status_id !== caseStatus.DRAFT
								}
							/>
							<Combo
								id="unit"
								className="mr-6"
								label={t("Unit")}
								options={units}
								value={unit}
								onChange={setUnit}
								readOnly={
									prescription && prescription.status_id !== caseStatus.DRAFT
								}
							/>
							<Combo
								id="route"
								label={t("Route")}
								options={routes}
								value={route}
								onChange={setRoute}
								readOnly={
									prescription && prescription.status_id !== caseStatus.DRAFT
								}
							/>
						</div>
						<div className="mt-4 flex gap-8 items-start">
							<div className="w-[200px] flex flex-col gap-6">
								<Input
									id="startDate"
									className="w-full shrink-0"
									label={t("Start date")}
									type="date"
									value={formatDateForInput(startDate)}
									readOnly={
										prescription && prescription.status_id !== caseStatus.DRAFT
									}
									onChange={(e) => setStartDate(e.target.value)}
									// onBlur={(e) => validateBirthDate(e.target)}
									onFocus={(e) => e.target.classList.remove("invalid")}
								/>
								<Input
									id="endDate"
									className="w-full shrink-0"
									label={t("End date")}
									type="date"
									value={formatDateForInput(endDate)}
									onChange={(e) => setEndDate(e.target.value)}
									readOnly={
										prescription && prescription.status_id !== caseStatus.DRAFT
									}
								/>
							</div>
							<Textarea
								id="notes"
								className="w-[490px] h-full max-h-auto ml-auto"
								label={t("Prescription notes (optional)")}
								value={notes}
								rows={3}
								onChange={(e) => setNotes(e.target.value)}
								readOnly={
									prescription && prescription.status_id !== caseStatus.DRAFT
								}
							/>
						</div>
					</form>
				</div>
				<aside className="aside flex flex-col gap-8">
					<div className="p-1 overflow-y-auto flex flex-col gap-8">
						<div>
							<h4 className="mb-1 font-semibold">&nbsp;</h4>
							<Textarea
								id="annotation"
								className="w-full h-[8rem]"
								label={t("Annotations")}
								value={annotation}
								onChange={(e) => setAnnotation(e.target.value)}
								readOnly={
									prescription && prescription.status_id !== caseStatus.DRAFT
								}
							/>
						</div>
						{showReviewForm && (
							<ReviewForm
								target={prescription}
								title={t(
									amI("Teacher")
										? "Review prescription"
										: "Prescription review",
								)}
								userData={userData}
								readOnly={!amI("Teacher")}
								buttonCaption={t("Review prescription")}
								/* onClose={() => setRefreshPrescription(true)} */
								onSubmit={handleSubmitReviewForm}
							/>
						)}
					</div>

					<div className="flex justify-end mt-auto">
						{!isLoading &&
							prescription &&
							prescription?.status_id !== caseStatus.DRAFT && (
								<Button onClick={onClose}>{t("Close")}</Button>
							)}
						{!isLoading &&
							(prescription === null ||
								prescription?.status_id === caseStatus.DRAFT) && (
								<ButtonGroup className="justify-end">
									<Button onClick={onClose}>{t("Close")}</Button>
									<Button
										className="btn-primary w-min self-end"
										onClick={handleSubmit}
									>
										{t("Save")}
									</Button>
								</ButtonGroup>
							)}
					</div>
				</aside>
			</div>
		</Modal>
	);
};

export default PrescriptionModal;
