import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Input from "components/Form/Input/Input";
import Button, { ButtonGroup } from "components/Form/Button/Button";

const DifficultyModal = (props) => {
	const { t } = useTranslation();
	const [difficulty, setDifficulty] = useState({
		name: props?.name || "",
		description: props?.description || "",
	});

	const handleSubmit = () => {
		props.onSubmit({ ...difficulty });
		setDifficulty({});
		props.onClose();
	};

	return (
		<Modal
			title={t("Edit difficulty level")}
			onClose={props.onClose}
			onSubmit={props.onSubmit}
		>
			<form className="flex flex-col gap-4 w-full" onSubmit={handleSubmit}>
				<p className="max-w-96 mt-4">
					{t("You can change the name or description of the difficulty level.")}
				</p>
				<Input
					id="title"
					className="mt-4"
					label={t("Title")}
					type="text"
					name="Title"
					value={difficulty.name}
					onChange={(e) =>
						setDifficulty({ ...difficulty, name: e.target.value })
					}
					autoFocus
					required
				/>
				<Input
					id="class-id"
					className="mt-4"
					label={t("Description")}
					type="text"
					name="Description"
					value={difficulty.description}
					onChange={(e) =>
						setDifficulty({ ...difficulty, description: e.target.value })
					}
				/>
				<ButtonGroup>
					<Button onClick={props?.onClose} type="button">
						{t("Cancel")}
					</Button>
					<Button className="btn-primary">{t("Save")}</Button>
				</ButtonGroup>
			</form>
		</Modal>
	);
};

export default DifficultyModal;
