import { Tab } from "@headlessui/react";
import { useTranslation } from "react-i18next";

import Badge from "components/Badge/Badge";

import { getDate } from "utils/utils";

export const OverviewTab = ({ selected }) => {
  return (
    <Tab
      className={({ selected }) =>
        `max-w-56 py-1 px-2 -mb-[1px] mt-[1px] text-sm font-medium border border-gray-400 rounded-t
               focus-visible:outline outline-2 focus-visible:outline-primary outline-offset-1
               truncate
               ${
                 selected
                   ? " border-b-4 border-b-primary text-primary bg-gray-100"
                   : " border-b-0 border-b-transparent text-gray-500"
               }`
      }
    >
      <i className="ri-list-check leading-none"></i>
    </Tab>
  );
};

export const OverviewPanel = ({ cases, onClick }) => {
  const { t } = useTranslation();

  return (
    <Tab.Panel className="p-4 flex flex-col gap-6">
      <h2 className="mb-0">{t("Cases overview in this session")}</h2>
      <p className="message__note -mt-2 mb-2 max-w-fit">
        <i className="ri-book-open-line"></i>
        {t(
          "Overview of all the cases in this session an their reviews.\nClick on the title to view the cases' details or navigate with the tabs.",
        )}
      </p>
      <table>
        <thead className="bg-slate-100">
          <tr className="[&>th]:font-medium [&>th]:text-[0.85rem]">
            <th className="text-left">{t("Title")}</th>
            <th className="text-center">{t("Grade")}</th>
            <th className="text-center">{t("Reviewer")}</th>
            <th className="text-center">{t("Review date")}</th>
          </tr>
        </thead>
        <tbody>
          {cases.length === 0 && (
            <tr>
              <td
                colSpan={4}
                className="py-8 text-slate-500 text-center whitespace-pre-line"
              >
                {t(
                  "No cases started yet!\nGo to the first tab to start a case.",
                )}
              </td>
            </tr>
          )}
          {cases.map((caseItem, index) => (
            <tr
              key={index}
              className="border-b hover:bg-slate-100 cursor-pointer"
              onClick={() => onClick(index + 1)}
            >
              <td>
                {caseItem?.story?.title ||
                  `${caseItem?.patient?.first_name} ${caseItem?.patient?.last_name}`.trim()}
              </td>
              <td className="text-center">
                {(caseItem?.grade && (
                  <Badge
                    className="badge-status text-xs"
                    value={caseItem?.grade}
                  />
                )) ||
                  "-"}
              </td>
              <td className="text-center">{caseItem?.reviewer || "-"}</td>
              <td className="text-center">
                {getDate(caseItem?.reviewed_at) || "-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Tab.Panel>
  );
};
