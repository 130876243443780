import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { DragDropContext } from "@hello-pangea/dnd";
import { twMerge } from "tailwind-merge";

import { useUser } from "context/UserContext";

import Badge from "components/Badge/Badge";
import Button from "components/Form/Button/Button";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import SingleClassHeader from "components/SingleClassHeader";
import StoriesTable from "components/StoriesTable/StoriesTable";
import ClassSessionsTable from "components/ClassSessionsTable/ClassSessionsTable";
import ClassModal from "components/ClassModal";
import StoryModal from "components/StoryModal";
import ConfirmationModal from "components/ConfirmationModal";
import InformationModal from "components/InformationModal";

import {
	getClass,
	updateClass,
	deleteClass,
	getStoriesFromClass,
	addStoriesToClass,
	removeStoriesFromClass,
} from "api/classes";
import { getClassSessions } from "api/sessions";
import { getStories, createStory } from "api/stories";
import { sendBulkNotification } from "api/reviews";

import { getDate } from "utils/utils";

const SingleClass = ({ readOnly = false }) => {
	const { classId } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { canIDelete } = useUser();

	const [singleClass, setSingleClass] = useState(null);
	const [allStories, setAllStories] = useState([]);
	const [availableStories, setAvailableStories] = useState([]);
	const [classStories, setClassStories] = useState([]);
	const [classSessions, setClassSessions] = useState([]);
	const [reviewedCount, setReviewedCount] = useState(0);

	const [isLoadingAvailableStories, setIsLoadingAvailableStories] =
		useState(false);
	const [isLoadingClassStories, setIsLoadingClassStories] = useState(false);

	const [refreshClass, setRefreshClass] = useState(true);
	const [refreshStories, setRefreshStories] = useState(true);
	const [refreshClassStories, setRefreshClassStories] = useState(true);
	const [refreshSubmissionsTable, setRefreshSubmissionsTable] = useState(true);

	// modals - states
	const [showEditClassModal, setShowEditClassModal] = useState(false);
	const [showPublishClassModal, setShowPublishClassModal] = useState(false);
	const [showArchiveClassModal, setShowArchiveClassModal] = useState(false);
	const [showDeleteClassModal, setShowDeleteClassModal] = useState(false);
	const [showStoryModal, setShowStoryModal] = useState(false);
	const [showSendClassIdModal, setShowSendClassIdModal] = useState(false);
	const [showSendBulkNotificationModal, setShowSendBulkNotificationModal] =
		useState(false);

	/**
	 * class sessions table - columns & actions
	 *
	 *  these are the actions allowed depending on the role
	 */

	const columns = useMemo(
		() => [
			{
				Header: "Author",
				accessor: "creator",
				cssClass: "font-medium",
			},
			{
				Header: "Date created",
				accessor: "created_at",
				cssClass: "text-center min-cell-width",
				Cell: ({ cell: { value } }) => getDate(value),
			},
			{
				Header: "Grade",
				accessor: "grade",
				cssClass: "text-center min-cell-width",
				Cell: ({ cell: { value } }) =>
					value ? (
						<Badge className="badge-status text-xs" value={value} />
					) : (
						"-"
					),
			},
			{
				Header: "Reviewer",
				accessor: "reviewer",
				cssClass: "min-cell-width text-center",
			},
		],
		[],
	);

	const rowActions = [];

	const LocalActions = () => {
		return (
			<div className="local-actions -ml-1 flex items-center text-gray-500">
				<Button
					className="btn-action h-8 w-6 p-0 mr-2 font-light text-2xl text-inherit"
					title={t("Back")}
					onClick={() => navigate(-1)}
				>
					<i className="ri-arrow-left-line"></i>
				</Button>
				<div className="flex gap-3 text-sm">
					<Button
						className="inline-action inline-flex gap-1 items-center"
						onClick={() => setShowEditClassModal(true)}
						title={t("Edit title")}
					>
						<i className="ri-pencil-line"></i>
						<span className="text-sm">{t("Edit title")}</span>
					</Button>
					<Button
						className="inline-action inline-flex gap-1 items-center"
						onClick={() => setShowPublishClassModal(true)}
						title={t(
							singleClass.is_published ? "Unpublish class" : "Publish class",
						)}
					>
						<i
							className={
								singleClass.is_published ? "ri-eye-off-line" : "ri-eye-line"
							}
						></i>
						<span className="text-sm">
							{t(
								singleClass.is_published ? "Unpublish class" : "Publish class",
							)}
						</span>
					</Button>
					<Button
						className="inline-action inline-flex gap-1 items-center"
						onClick={() => setShowArchiveClassModal(true)}
					>
						{singleClass.is_active ? (
							<>
								<i className="ri-inbox-archive-line"></i>
								<span className="text-sm">{t("Archive class")}</span>
							</>
						) : (
							<>
								<i className="ri-inbox-unarchive-line"></i>
								<span className="text-sm">{t("Unarchive class")}</span>
							</>
						)}
					</Button>
					<Button
						className="inline-action inline-flex gap-1 items-center"
						disabled={singleClass?.total_sessions === 0}
						title={
							singleClass?.total_sessions === 0
								? t("There are no submissions yet.")
								: undefined
						}
						onClick={() => setShowSendBulkNotificationModal(true)}
					>
						<i className="ri-mail-send-line"></i>
						<span className="text-sm">{t("Send all feedback")}</span>
					</Button>
					<Button
						className="inline-action danger inline-flex gap-1 items-center"
						disabled={
							!canIDelete(singleClass.creator_id) ||
							singleClass.total_sessions > 0
						}
						onClick={() => setShowDeleteClassModal(true)}
						title={
							!canIDelete(singleClass.creator_id) ||
							singleClass.total_sessions > 0
								? t(
										"This class contains submissions, or you lack the necessary permissions:\nauthor or administrator",
								  )
								: undefined
						}
					>
						<i className="ri-delete-bin-line"></i>
						<span className="text-sm">{t("Delete class")}</span>
					</Button>
					<HelpTooltip
						text={t(
							"Use the single class view to design or edit a class.\nAfter publishing you have an overview of the submitted sessions.",
						)}
					/>
				</div>
			</div>
		);
	};

	/**
	 * ======== class handlers ========
	 *
	 * - update class
	 * - save class
	 * - publish class
	 * - un/archive class
	 * - delete class
	 * - review all sessions
	 * - send all feedback
	 */

	const handleUpdateClass = async ({ description, isActive, isPublished }) => {
		try {
			const response = await updateClass({
				classId,
				description,
				isActive,
				isPublished,
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			}

			setSingleClass({
				...singleClass,
				description,
				is_active: isActive,
				is_published: isPublished,
			});

			toast.success(t("Class updated!"));
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowEditClassModal(false);
		}
	};

	const handlePublishClass = async () => {
		const isPublished = !singleClass.is_published;

		try {
			const response = await updateClass({
				classId,
				description: singleClass.description,
				isActive: singleClass.is_active,
				isPublished,
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			}

			setSingleClass({
				...singleClass,
				description: singleClass.description,
				is_active: singleClass.is_active,
				is_published: isPublished,
			});

			if (isPublished) {
				setShowSendClassIdModal(true);
				toast.success(t("Class published!"));
			} else {
				toast.success(t("Class unpublished!"));
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowPublishClassModal(false);
		}
	};

	const handleArchiveClass = async () => {
		const isActive = !singleClass.is_active;

		try {
			const response = await updateClass({
				classId,
				description: singleClass.description,
				isPublished: singleClass.is_published,
				isActive,
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			} else {
				setSingleClass({
					...singleClass,
					description: singleClass.description,
					is_published: singleClass.is_published,
					is_active: isActive,
				});

				if (isActive) {
					toast.success(t("Class unarchived!"));
				} else {
					toast.success(t("Class archived!"));
				}
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowArchiveClassModal(false);
		}
	};

	const handleDeleteClass = async () => {
		try {
			const response = await deleteClass(classId);

			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			} else {
				toast.success(t("Class deleted!"));
				navigate("/classes");
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowDeleteClassModal(false);
		}
	};

	const handleSendBulkNotificaion = () => {
		try {
			const response = sendBulkNotification(classId);

			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			} else {
				toast.success(t("Feedback sent!"));
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowSendBulkNotificationModal(false);
		}
	};

	/**
	 * ======== stories handlers ========
	 *
	 * - handle submit story
	 * - handle add story by id
	 * - handle remove story by id
	 * - handle drag end
	 */

	async function handleSubmitStory(data) {
		try {
			const response = await createStory({ ...data });

			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			} else {
				// add story to the list of selected class stories
				// it will automatically be removed from the available stories
				setClassStories([...classStories, response]);
				toast.success(t("Story created!"));
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowStoryModal(false);
		}
	}

	const addStoryToClass = async (id, order) => {
		// console.log("adding story with id", id);

		try {
			const response = await addStoriesToClass({
				classId,
				storyIds: [id],
				order,
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const removeStoryFromClass = async (id) => {
		// console.log("removing story with id", id);

		try {
			const response = await removeStoriesFromClass({
				classId,
				storyIds: [id],
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const updateStoriesOrder = async (stories) => {
		console.log("updating stories order");

		try {
			const response = await addStoriesToClass({
				classId,
				storyIds: stories.map((story) => story.id),
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const addStoryToClassList = (source, destination) => {
		const sourceItems = Array.from(availableStories);
		const [movedItem] = sourceItems.splice(source.index, 1);
		setAvailableStories(sourceItems);

		const destinationItems = Array.from(classStories);
		destinationItems.splice(destination.index, 0, movedItem);
		setClassStories(destinationItems);
	};

	const removeStoryFromClassList = (source, destination) => {
		const sourceItems = Array.from(classStories);
		const [movedItem] = sourceItems.splice(source.index, 1);
		setClassStories(sourceItems);

		const destinationItems = Array.from(availableStories);
		destinationItems.splice(destination.index, 0, movedItem);
		setAvailableStories(destinationItems);
	};

	const reorderList = (list, startIndex, endIndex) => {
		const items = Array.from(list);

		const [reorderedItem] = items.splice(startIndex, 1);
		items.splice(endIndex, 0, reorderedItem);

		return items;
	};

	const handleDragEnd = (event) => {
		const { source, destination, draggableId } = event;

		// dropped outside any droppable area does nothing
		if (!destination) return;

		/**
		 * reorder in the list or move between lists
		 */
		if (source.droppableId === destination.droppableId) {
			if (source.index !== destination.index) {
				if (source.droppableId === "class-stories") {
					const items = reorderList(
						classStories,
						source.index,
						destination.index,
					);
					setClassStories(items);
					// update the database with the new order
					updateStoriesOrder(items);
				}
				if (source.droppableId === "available-stories") {
					const items = reorderList(
						availableStories,
						source.index,
						destination.index,
					);
					setAvailableStories(items);
				}
			}
		} else {
			// move item from one list to another only altering the classStories and availableStories states
			if (source.droppableId === "class-stories") {
				removeStoryFromClassList(source, destination);
				// update the database as well
				removeStoryFromClass(draggableId);
			} else {
				addStoryToClassList(source, destination);
				// update the database as well
				addStoryToClass(draggableId, destination);
			}
		}
	};

	const getBulkNotificationMessage = () => {
		const { total_sessions, reviewed_sessions } = singleClass;
		const allReviewed = total_sessions === reviewedCount;

		return (
			<>
				<span className="block">
					{t(
						"You are about to notify all students who submitted a session to this class about the new status of their sessions.",
					)}
				</span>
				<span className="block mt-8 mb-4 text-center text-lg font-semibold">
					{t("Submitted sessions")}: {total_sessions}
					<br />
					{t("Reviewed sessions")}: {reviewedCount}
				</span>
				<span
					className={twMerge(
						"block my-4 font-semibold text-center text-sm",
						allReviewed ? "text-green-700" : "text-red-700",
					)}
				>
					{allReviewed
						? t("All submissions have been reviewed!")
						: t("Not all submissions have been reviewed!")}
				</span>
				<span className="block text-center mt-12">
					{t("Are you sure you want to proceed?")}
				</span>
			</>
		);
	};

	// get the class data

	useEffect(() => {
		if (!refreshClass) return;

		const fetchClass = async () => {
			try {
				const response = await getClass(classId);
				setSingleClass(response);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshClass(false);
			}
		};

		fetchClass();
	}, [classId, refreshClass]);

	// get all active stories

	useEffect(() => {
		if (!refreshStories) return;

		const fetchStories = async () => {
			try {
				setIsLoadingAvailableStories(true);
				const response = await getStories();
				response.data = response.data.filter((story) => story.is_active);
				setAllStories(response.data);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setIsLoadingAvailableStories(false);
				setRefreshStories(false);
			}
		};

		fetchStories();
	}, [refreshStories]);

	// get the class stories

	useEffect(() => {
		if (!refreshClassStories) return;

		const fetchClassStories = async () => {
			try {
				setIsLoadingClassStories(true);
				const response = await getStoriesFromClass(classId);
				setClassStories(response);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setIsLoadingClassStories(false);
				setRefreshClassStories(false);
			}
		};

		fetchClassStories();
	}, [classId, refreshClassStories]);

	// compute the available stories (right side panel) with a derived state

	useEffect(() => {
		const selectedIds = classStories.map((story) => story?.id);
		setAvailableStories(
			allStories.filter((story) => !selectedIds.includes(story.id)),
		);
	}, [allStories, classStories]);

	// get the submitted sessions

	useEffect(() => {
		if (!refreshSubmissionsTable) return;

		const fetchSessions = async () => {
			try {
				const data = await getClassSessions(classId);
				setClassSessions(data);

				// calculate the count of reviewed sessions
				setReviewedCount(
					data.filter((session) => session.grade !== null).length,
				);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshSubmissionsTable(false);
			}
		};

		fetchSessions();
	}, [classId, refreshSubmissionsTable]);

	if (singleClass === null) return null;

	return (
		<>
			<div className="single-class h-full w-full overflow-hidden gap-4 items-stretch">
				<div className="view w-full">
					<LocalActions />
					<SingleClassHeader
						singleClass={{ ...singleClass, reviewed_sessions: reviewedCount }}
					/>
					<DragDropContext onDragEnd={handleDragEnd}>
						<div className="content ml-8 mt-2 overflow-hidden">
							<div className="h-full mb-2 flex gap-24">
								<div className="w-1/2 flex flex-col gap-4">
									<div className="flex gap-2 items-center">
										<h2 className="m-0">{t("Stories in this class")}</h2>
										<HelpTooltip
											text={t(
												"List of stories selected for this class. You can change the order by dragging and dropping the stories.",
											)}
										/>
									</div>
									<StoriesTable
										id="class-stories"
										data={classStories}
										actions={rowActions}
										showSearch={false}
										noDataMessage={
											"No stories selected yet.\nAdd stories from the existing ones (right column) or create a new one."
										}
										isLoading={isLoadingClassStories}
										isDragDisabled={singleClass.is_published}
										onDragEnd={handleDragEnd}
									/>
								</div>

								<aside className="w-1/2 h-full flex flex-col gap-4">
									{!singleClass.is_published ? (
										<>
											<h2 className="m-0">{t("Available stories")}</h2>
											<StoriesTable
												id="available-stories"
												data={availableStories}
												noDataMessage={t("No (further) stories available.")}
												actions={[]}
												showSearch={true}
												showFilter={true}
												isLoading={isLoadingAvailableStories}
												onDragEnd={handleDragEnd}
											/>
										</>
									) : (
										<>
											<div className="flex gap-2 items-center">
												<h2 className="m-0">
													{t("List of sessions in this class")}
												</h2>
												<HelpTooltip
													text={t(
														"List of all the sessions submitted for this class. You can review them and give a grade.",
													)}
												/>
											</div>
											<ClassSessionsTable
												data={classSessions}
												columns={columns}
												readOnly={readOnly}
												showSearch={false}
												onRowClick={(row) => {
													navigate(`/sessions/${row.original.id}`);
												}}
											/>
										</>
									)}
								</aside>
							</div>
						</div>
					</DragDropContext>
				</div>
			</div>

			{showEditClassModal && (
				<ClassModal
					classId={classId}
					description={singleClass.description}
					isActive={singleClass.is_active}
					isPublished={singleClass.is_published}
					onClose={() => setShowEditClassModal(false)}
					onSubmit={handleUpdateClass}
				/>
			)}

			{showPublishClassModal && (
				<ConfirmationModal
					title={
						singleClass.is_published
							? t("Unpublish class?")
							: t("Publish class?")
					}
					message={
						singleClass.is_published
							? t("Are you sure you want to unpublish this class?")
							: t("Are you sure you want to publish this class?")
					}
					yesMessage={singleClass.is_published ? t("Unpublish") : t("Publish")}
					onClose={() => setShowPublishClassModal(false)}
					onSubmit={handlePublishClass}
				/>
			)}

			{showSendClassIdModal && (
				<InformationModal
					title={t("Class created!")}
					onClose={() => setShowSendClassIdModal(false)}
					onSubmit={() => setShowSendClassIdModal(false)}
				>
					<div className="flex flex-col gap-2">
						<p className="self-center">{t("Class ID")}</p>
						<p className="self-center text-2xl font-bold">{classId}</p>
						<p className="whitespace-pre-line text-center">
							{t(
								"The class was successfully created!\nYou have to send the students the class ID manually.",
							)}
						</p>
					</div>
				</InformationModal>
			)}

			{showArchiveClassModal && (
				<ConfirmationModal
					title={
						singleClass.is_active ? t("Archive class?") : t("Unarchive class?")
					}
					message={
						singleClass.is_active
							? t("Are you sure you want to archive this class?")
							: t("Are you sure you want to unarchive this class?")
					}
					yesMessage={singleClass.is_active ? t("Archive") : t("Unarchive")}
					onClose={() => setShowArchiveClassModal(false)}
					onSubmit={handleArchiveClass}
				/>
			)}

			{showDeleteClassModal && (
				<ConfirmationModal
					title={t("Delete class?")}
					message={t(
						'Are you sure you want to delete the class "{{ title }}" (Class ID {{ id }})?\nThis operation cannot be undone.',
						{ title: singleClass.description, id: singleClass.id },
					)}
					yesMessage={t("Delete")}
					destructive={true}
					onClose={() => setShowDeleteClassModal(false)}
					onSubmit={handleDeleteClass}
				/>
			)}

			{showSendBulkNotificationModal && (
				<ConfirmationModal
					title={t("Send all feedback?")}
					message={getBulkNotificationMessage()}
					yesMessage={t("Send e-mails")}
					onClose={() => setShowSendBulkNotificationModal(false)}
					onSubmit={handleSendBulkNotificaion}
				/>
			)}

			{showStoryModal && (
				<StoryModal
					onClose={() => setShowStoryModal(false)}
					onSubmit={handleSubmitStory}
				/>
			)}
		</>
	);
};

SingleClass.displayName = "SingleClass";

export default SingleClass;
