import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useUser } from "context/UserContext";
import { sendCode } from "api/auth.js";

import Input from "components/Form/Input/Input";
import Button from "components/Form/Button/Button";

const isValidInput = () => {
  let isValid = true;

  // validation comes here

  return isValid;
};

/**
 * STEP 1/3 - Email input form
 */
const Reset = () => {
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setUserData } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validation
    if (!isValidInput()) {
    }

    // authentication
    try {
      const response = await sendCode({ email });
      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        throw new Error(
          "There was an error. Retry or contact the support. " +
            response.message,
        );
      }
      setUserData({ email: email });
      setEmail("");
      navigate("/reset-code");
    } catch (error) {
      setErrorMsg(String(error));
    }
  };

  return (
    <>
      <form
        className="w-80 p-5 my-5 border rounded shadow flex flex-col gap-5"
        onSubmit={handleSubmit}
      >
        <h2>{t("Forgot password?")}</h2>
        <p className="text-sm">
          {t(
            "No problem. We can send you a link that will reset your password.",
          )}
        </p>
        <Input
          id="email"
          label={t("E-mail")}
          type="email"
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button className="btn-primary my-8">{t("Send code")}</Button>
      </form>
      <p className="text-sm">
        {t("Return to the")} <Link to="/login">{t("Log in")}</Link> {t("page")}
      </p>
    </>
  );
};

Reset.displayName = "Reset";

export default Reset;
