import { useMemo, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer, cssTransition, toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";
import { useHelp } from "context/HelpContext";

import { getAuthToken } from "api/auth";

import TopNav from "components/TopNav/TopNav";
import Sidebar from "components/Sidebar/Sidebar";
import HelpSidebar from "components/HelpSidebar/HelpSidebar";
import LoginModal from "components/LoginModal";

import "react-toastify/dist/ReactToastify.css";
import "./MainLayout.scss";

const MainLayout = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    authData,
    setAuthData,
    clearAuthData,
    isTokenExpiring,
    isTokenExpired,
  } = useAuth();
  const { canI, amI, clearUserData } = useUser();
  const { isVisible, hideHelp } = useHelp();
  const [showLoginModal, setShowLoginModal] = useState(false);

  const navLinks = useMemo(
    () => [
      canI("view", "Dashboard") && {
        path: "/dashboard",
        caption: t("Dashboard"),
        icon: "ri-dashboard-2-line",
      },
      canI("view", "Classes") && {
        path: "/classes",
        caption: t("Classes"),
        icon: "ri-school-line",
      },
      canI("view", "Stories") && {
        path: "/stories",
        caption: t("Stories"),
        icon: "ri-file-list-3-line",
      },
      canI("view", "Patients") && {
        path: "/patients",
        caption: t("Patients"),
        icon: "ri-folder-user-line",
      },
      canI("view", "Sessions") && {
        path: "/sessions",
        caption: amI("Student") ? t("My sessions") : t("Sessions"),
        icon: "ri-folder-2-line",
      },
      canI("view", "Users") && { caption: "_separator_" },
      canI("view", "Users") && {
        path: "/users",
        caption: t("Users"),
        icon: "ri-group-line",
      },
      canI("view", "Settings") && {
        path: "/settings",
        caption: t("Settings"),
        icon: "ri-settings-3-line",
      },
      {
        path: "/profile",
        caption: t("My profile"),
        icon: "ri-account-circle-line",
        className: "mt-auto",
      },
    ],
    [canI, t, amI],
  );

  const handleLogin = async ({ username, password }) => {
    try {
      const response = await getAuthToken({ username, password });

      if (response.code === "ERR_BAD_REQUEST") {
        const errorDetail = response.response.data.detail;
        toast.error(
          t(errorDetail || "An error occurred during authentication."),
        );
        throw new Error(errorDetail || "Authentication failed.");
      } else if (response.access_token) {
        setAuthData(response);
        setShowLoginModal(false);
      }
    } catch (error) {
      toast.error(t("An error occurred during authentication."));
      console.error("Login Error:", error); // Add logging for debugging
    }
  };

  const handleLogout = () => {
    try {
      clearAuthData();
      clearUserData();
    } catch (error) {
      console.error("Logout Error:", error);
    } finally {
      setShowLoginModal(false);
    }
  };

  useEffect(() => {
    if (isTokenExpired === false) {
      if (isTokenExpiring === true) {
        toast.warn(
          <div className="flex gap-8 justify-between items-center whitespace-pre-line">
            <p>
              {t(
                "Your session is about to expire!\nYou can renew the session now.",
              )}
            </p>
            <button
              className="btn mr-2"
              onClick={() => setShowLoginModal(true)}
            >
              {t("Re-authenticate")}
            </button>
          </div>,
          {
            toastId: "expiring",
            className: "w-auto -ml-[200px]",
            autoClose: 50000,
          },
        );
      }
    } else {
      toast.dismiss();
      setShowLoginModal(true);
    }
  }, [authData, navigate, isTokenExpired, isTokenExpiring, t]);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        transition={cssTransition({
          enter: "slide-top",
          exit: "slide-top-hide",
        })}
      />
      <TopNav />
      <Sidebar navLinks={navLinks.filter((item) => item !== false)} />
      <main className="main-content logged-in mt-[32px] flex overflow-x-hidden">
        <Outlet />
        {isVisible && <HelpSidebar onClose={() => hideHelp()} />}
      </main>

      {showLoginModal && (
        <LoginModal onSubmit={handleLogin} onClose={handleLogout} />
      )}
    </>
  );
};

export default MainLayout;
