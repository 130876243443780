import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";

import Modal from "components/Modal/Modal";
import Button from "components/Form/Button/Button";
import PatientsTable from "components/PatientsTable/PatientsTable";
import PatientPreview from "components/PatientPreview/PatientPreview";

import { getPatients, getPatient } from "api/patients";

/**
 * patientData is only the patient.patient object
 */

const SelectPatientModal = ({ patientData, onSubmit, ...props }) => {
	const { t } = useTranslation();

	const [patient, setPatient] = useState(
		patientData || {
			first_name: "",
			last_name: "",
			birth_date: "",
			gender: "",
			body_weight: null,
			body_unit_id: null,
			body_unit: null,
			kidney_function: null,
		},
	);
	const [patientHistory, setPatientHistory] = useState(null);

	// table - states
	const [patients, setPatients] = useState([]);
	const [refreshTable, setRefreshTable] = useState(true);
	const [refreshHistory, setRefreshHistory] = useState(true);

	const handleRowClick = async (row) => {
		setPatient(row.original);
		setRefreshHistory(true);
	};

	const handleSubmit = () => {
		onSubmit(patient);
		setPatient(null);
		setPatientHistory(null);
		props.onClose();
	};

	// get patient history

	useEffect(() => {
		if (!patient?.id) return;
		if (!refreshHistory) return;

		const fetchPatient = async () => {
			try {
				const response = await getPatient(patient.id);

				if (response === "ERR_BAD_REQUEST") {
					toast.error(t(response.response.response.data));
					console.log(response);
				} else {
					console.log(response);
					setPatientHistory(response?.patient_history);
					setRefreshHistory(false);
				}
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		fetchPatient();
	}, [patient, refreshHistory, t]);

	// get all patients

	useEffect(() => {
		if (!refreshTable) return;

		const fetchPatients = async () => {
			try {
				const response = await getPatients();

				if (response === "ERR_BAD_REQUEST") {
					toast.error(t(response.response.response.data));
					console.log(response);
				} else {
					setPatients(response);
					setRefreshTable(false);
				}
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		fetchPatients();
	}, [refreshTable, t]);

	return (
		<Modal
			title={t("Select patient")}
			isOpen={props.isOpen}
			onClose={props.onClose}
			onSubmit={onSubmit}
			className="select-patient-modal w-3/5 h-4/5 w-[1200px]"
		>
			<div className="flex h-full w-full gap-6">
				<div className="flex w-3/4 flex-col gap-6 overflow-auto">
					<h3>{t("Patient list")}</h3>
					<PatientsTable
						data={patients}
						initialState={{ hiddenColumns: ["accessor", "is_active"] }}
						selectedId={patient?.id}
						onRowClick={handleRowClick}
					/>
				</div>
				<div className="aside p-1 pt-4 flex flex-col">
					<PatientPreview
						patient={{ patient, patient_history: patientHistory }}
					/>
					{patient && (
						<Button
							className="btn-action w-auto mt-1 self-end inline-flex gap-1 text-slate-700 hover:text-red-600 hover:opacity-100"
							onClick={() => {
								setPatient(null);
								setPatientHistory(null);
							}}
						>
							<i className="ri-delete-bin-line"></i>
							{t("Remove patient")}
						</Button>
					)}
					<Button
						className={twMerge(
							"btn-primary mt-auto self-end",
							!patient && "disabled",
						)}
						onClick={handleSubmit}
					>
						{t("Select patient")}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default SelectPatientModal;
