import { useTranslation } from "react-i18next";

import Badge from "components/Badge/Badge";

import { getDate } from "utils/utils";

/**
 * patient is the complete patient object with patient_history
 */

const PatientPreview = ({ title = "Patient information", patient }) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col gap-4 p-4 bg-slate-100 border rounded">
			<h3 className="mb-4 text-slate-700">{t(title)}</h3>
			<div className="grid grid-cols-2 gap-y-2 text-sm [&>label]:text-[0.85rem] [&>label]:font-semibold [&>label]:text-slate-700">
				<label>{t("Patient ID")}</label>
				{patient?.patient?.id || "-"}
				<label>{t("First name")}</label>
				{patient?.patient?.first_name || "-"}
				<label>{t("Last name")}</label>
				{patient?.patient?.last_name || "-"}
				<label>{t("Date of birth")}</label>
				{getDate(patient?.patient?.birth_date) || "-"}
				<label>{t("Age")}</label>
				{patient?.patient?.age || "-"}
				<label>{t("Sex")}</label>
				{t(patient?.patient?.gender) || "-"}
				<label>{t("Body weight")}</label>
				{`${patient?.patient?.body_weight || "-"} ${t(
					patient?.patient?.body_unit,
				)}` || "-"}
				<label>{t("eGFR")}</label>
				{patient?.patient?.kidney_function || "-"}
				{patient?.patient?.kidney_function && t("ml/min/1.73m²")}
				{patient?.patient_history?.length > 0 && (
					<>
						<label className="mt-3 col-span-2">{t("Medical history")}</label>
						<div className="col-span-2 flex flex-wrap gap-2">
							{patient.patient_history.map((item, idx) => (
								<Badge
									key={idx}
									className="!m-0 bg-slate-400"
									value={item.description}
								/>
							))}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default PatientPreview;
