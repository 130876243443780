import { twMerge } from "tailwind-merge";

import "./Textarea.scss";

const Textarea = ({
  id,
  className = "",
  label,
  value,
  rows = 5,
  onChange,
  readOnly,
  ...rest
}) => {
  return (
    <div
      className={twMerge("textarea-container", className)}
      readOnly={readOnly}
    >
      <label htmlFor={id} className={value && "filled"}>
        {label}
      </label>
      <textarea
        id={id}
        value={value}
        rows={rows}
        onChange={onChange}
        readOnly={readOnly}
        {...rest}
      />
    </div>
  );
};

export default Textarea;
