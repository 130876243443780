import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import "./Badge.scss";

const StatusBadge = ({ value, className, ...props }) => {
  const { t } = useTranslation();

  const classByValue = `badge-value__${String(value)
    .toLowerCase()
    .replace(" ", "-")}`;

  return (
    <span className={twMerge("badge", classByValue, className)} {...props}>
      {t(value)}
    </span>
  );
};

export default StatusBadge;
