import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Button from "components/Form/Button/Button";
import CaseTabs from "components/Tabs/CaseTabs";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import SingleSessionHeader from "components/SingleSessionHeader";
import ReviewForm from "components/ReviewForm/ReviewForm";
import ConfirmationModal from "components/ConfirmationModal";
import SessionNameModal from "components/SessionNameModal";
import SelectStoryModal from "components/SelectStoryModal";
import SelectPatientModal from "components/SelectPatientModal";

import { getSession, updateSession, deleteSession } from "api/sessions";
import {
	getCases,
	getCase,
	createCase,
	updateCase,
	deleteCase,
} from "api/cases";
import {
	reviewSession,
	reviewTreatmentPlan,
	sendNotification,
} from "api/reviews";

import { useUser } from "context/UserContext";

import { getHelpText } from "utils/helpText";

const SingleSession = ({ readOnly = false }) => {
	const { sessionId } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { userData, canI, amI } = useUser();

	const [session, setSession] = useState([]);
	const [refreshSession, setRefreshSession] = useState(true);

	// selected tab index
	const [selectedIndex, setSelectedIndex] = useState(0);

	const [cases, setCases] = useState([]);
	const [currentCase, setCurrentCase] = useState({});
	const [refreshCase, setRefreshCase] = useState(false);
	const [refreshCases, setRefreshCases] = useState(true);

	const [showEditTitleModal, setShowEditTitleModal] = useState(false);
	const [showDeleteSessionModal, setShowDeleteSessionModal] = useState(false);
	const [showSubmitSessionModal, setShowSubmitSessionModal] = useState(false);
	const [showSelectStoryModal, setShowSelectStoryModal] = useState(false);
	const [showSelectPatientModal, setShowSelectPatientModal] = useState(false);
	const [showDeleteCaseModal, setShowDeleteCaseModal] = useState(false);
	const [showSendNotificationModal, setShowSendNotificationModal] =
		useState(false);

	const getNotificationMessage = () => {
		return (
			<>
				<span className="block mb-8">
					{t(
						"The student will receive an e-mail of the completed feedback. You can change the feedback later, but an e-mail about this will not be automatically sent to the student.",
					)}
				</span>
				<span className="block text-center">
					{t("Send an e-mail to {{ email }}?", {
						email: session?.creator_email,
					})}
				</span>
			</>
		);
	};

	const LocalActions = () => {
		return (
			<div className="local-actions -ml-1 flex items-center text-gray-500">
				<Button
					className="btn-action h-8 w-6 p-0 mr-2 font-light text-2xl text-inherit"
					title={t("Back")}
					onClick={() => navigate(-1)}
				>
					<i className="ri-arrow-left-line"></i>
				</Button>
				<div className="flex gap-3 text-sm">
					<Button
						className="inline-action inline-flex gap-1 items-center"
						title={t("Edit session name")}
						disabled={session.status !== "Draft"}
						onClick={() => setShowEditTitleModal(true)}
					>
						<i className="ri-pencil-line"></i>
						{t("Edit session name")}
					</Button>
					<Button
						className="inline-action inline-flex gap-1 items-center"
						disabled={session.status !== "Draft" || cases.length === 0}
						onClick={() => setShowSubmitSessionModal(true)}
					>
						<i className="ri-mail-send-line"></i>
						{session.session_type === "Self-Study"
							? t("Request feedback")
							: t("Submit session")}
					</Button>
					{amI("Teacher") && (
						<Button
							className="inline-action inline-flex gap-1 items-center"
							disabled={session.status === "Draft"}
							onClick={() => setShowSendNotificationModal(true)}
						>
							<i className="ri-mail-send-line"></i>
							{t("Send feedback")}
						</Button>
					)}
					<Button
						className="inline-action danger inline-flex gap-1 items-center"
						disabled={
							!(canI("delete", "SingleSession") && session.status === "Draft")
						}
						onClick={() => setShowDeleteSessionModal(true)}
					>
						<i className="ri-delete-bin-line"></i>
						{t("Delete session")}
					</Button>
					<HelpTooltip text={t(getHelpText(session.status))} />
				</div>
			</div>
		);
	};

	/**
	 * SESSION HANDLERS
	 */

	const handleUpdateTitle = async (description) => {
		try {
			const response = await updateSession({
				sessionId,
				description,
				classId: 0,
				statusId: 1,
			});
			if (response.status === 200 || response.status === 201) {
				setSession({ ...session, description: description });
				toast.success(t("Session name updated!"));
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowEditTitleModal(false);
		}
	};

	const handleDeleteSession = async () => {
		try {
			const response = await deleteSession(sessionId);
			if (response.status === 200 || response.status === 201) {
				toast.success(t("Session deleted!"));
				navigate("/sessions");
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowDeleteSessionModal(false);
		}
	};

	const handleSubmitSession = async () => {
		try {
			const response = await updateSession({
				sessionId: session.id,
				description: session.description,
				classId: session.class_id,
				statusId: 2,
			});

			if (response.status === 200 || response.status === 201) {
				// setSession({ ...session, status: "Submitted" });
				setRefreshSession(true);
				toast.success(t("Session submitted!"));
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowSubmitSessionModal(false);
		}
	};

	/**
	 * CASETABS HANDLERS
	 */

	const handleAddTab = () => {
		const draftCase = {
			id: 0,
			patient: null,
			story: null,
		};

		setSelectedIndex(cases.length + 1);
		setCurrentCase(draftCase);
		setCases([...cases, draftCase]);
	};

	const handleRemoveTab = () => {
		// remove last case if it is a draft case and select the one before the last
		if (cases[cases.length - 1].id === 0) {
			setSelectedIndex(cases.length - 1);
			// -2 because of deletion and because of the overview tab
			setCurrentCase(cases[cases.length - 2]);
			setCases(cases.slice(0, -1));
		}
	};

	const handleChangeTab = (index) => {
		setSelectedIndex(index);

		// index 0 is the overview
		if (index === 0) {
			setCurrentCase({});
		}

		if (index > 0 && cases[index - 1]) {
			setCurrentCase(cases[index - 1]);
		}
	};

	/**
	 * CASE HANDLERS
	 */

	const handleDeleteCase = async () => {
		try {
			const response = await deleteCase(currentCase.id);

			if (response.status === 200 || response.status === 201) {
				setSelectedIndex((prev) => (prev - 1 >= 0 ? prev - 1 : 0));
				setRefreshCases(true);
				toast.success(t("Case deleted!"));
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			const errorMessage = error.response?.data?.detail || "An error occurred";
			toast.error(t(errorMessage));
			console.error("An error occurred:", error);
		} finally {
			setShowDeleteCaseModal(false);
		}
	};

	/**
	 * STORY HANDLERS
	 */

	const handleSubmitStory = async (story) => {
		try {
			const response = !currentCase.id
				? await createCase({
						sessionId: sessionId,
						storyId: story.id,
				  })
				: await updateCase({
						id: currentCase.id,
						storyId: story.id,
				  });

			if (response.status !== 200 && response.status !== 201) {
				toast.error(t(response.response.data.detail));
				console.error("Unexpected response:", response);
			} else {
				/*
				setCases((prevCases) =>
					prevCases.map((c) =>
						c.id === currentCase.id
							? { ...currentCase, story_id: story.id, story: story }
							: c,
					),
				);
				*/
				setRefreshCases(true);
				toast.success(t("Story saved!"));
			}
		} catch (error) {
			const errorMessage = error.response?.data?.detail || "An error occurred";
			toast.error(t(errorMessage));
			console.error("An error occurred:", error);
		}
	};

	/**
	 * PATIENT HANDLERS
	 */

	const handleSubmitPatient = async (patient) => {
		try {
			const response = !currentCase.id
				? await createCase({
						sessionId: sessionId,
						patientId: patient.id,
				  })
				: await updateCase({
						id: currentCase?.id,
						patientId: patient.id,
				  });

			if (response.status !== 200 && response.status !== 201) {
				toast.error(t(response.response.data.detail));
				console.error("Unexpected response:", response);
			} else {
				// update all the cases
				/*
				setCases((prevCases) =>
					prevCases.map((c) =>
						c.id === currentCase.id
							? {
									...currentCase,
									patient_id: patient.id,
									patient: patient,
							  }
							: c,
					),
				);
				*/
				setRefreshCases(true);
				toast.success(t("Patient saved!"));
			}
		} catch (error) {
			const errorMessage = error.response?.data?.detail || "An error occurred";
			toast.error(t(errorMessage));
			console.error("An error occurred:", error);
		}
	};

	/**
	 * REVIEW HANDLERS
	 */

	const handleSubmitReviewSessionForm = async ({ grade, notes }) => {
		try {
			const response = await reviewSession({
				id: sessionId,
				gradeId: grade.value,
				notes: notes,
			});

			if (response?.status === 200 || response.status === 201) {
				setRefreshSession(true);
				toast.success(t("Session review saved!"));
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const handleSubmitReviewCaseForm = async ({ grade, notes }) => {
		try {
			const response = await reviewTreatmentPlan({
				id: currentCase.id,
				gradeId: grade.value,
				notes: notes,
			});

			if (response?.status === 200 || response.status === 201) {
				setRefreshCase(true);
				setRefreshSession(true);
				toast.success(t("Treatment plan review saved!"));
			} else {
				console.error("Unexpected response:", response);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const handleSendNotification = async () => {
		try {
			const response = await sendNotification(sessionId);

			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
				console.log(response);
			} else {
				if (response.status === 200 || response.status === 201) {
					toast.success(t("Review submitted!"));
					setRefreshSession(true);
				}
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setShowSendNotificationModal(false);
		}
	};

	// load the session

	useEffect(() => {
		if (!refreshSession) return;

		const loadSession = async () => {
			try {
				const data = await getSession(sessionId);
				setSession(data);
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshSession(false);
			}
		};

		loadSession();
	}, [sessionId, refreshSession]);

	// load all cases

	useEffect(() => {
		if (!refreshCases) return;

		const loadCases = async () => {
			try {
				const response = await getCases(sessionId);

				if (response.code === "ERR_BAD_REQUEST") {
					toast.error(t(response.response.data.detail));
				} else {
					if (response.length === 0) {
						setCases([]);
						setCurrentCase(1);
					} else {
						setCases(response.reverse());
						// set the current case based on the selected tab
						// the first tab is the overview tab, so subtracting 1
						setCurrentCase(response[selectedIndex - 1]);
					}
				}
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshCases(false);
			}
		};

		loadCases();
	}, [sessionId, selectedIndex, refreshCases, t]);

	// refresh a case

	useEffect(() => {
		if (!refreshCase) return;

		const loadCase = async () => {
			try {
				const response = await getCase(currentCase.id);

				if (response.code === "ERR_BAD_REQUEST") {
					toast.error(t(response.response.data.detail));
					console.log(response);
				} else {
					setCases((prevCases) =>
						prevCases.map((c) =>
							c.id === currentCase.id ? { ...currentCase, ...response } : c,
						),
					);

					// refresh the currentCase as well
					if (currentCase.id === response.id) {
						setCurrentCase(response);
					}
				}
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshCase(false);
			}
		};

		loadCase();
	}, [currentCase, refreshCase, t]);

	return (
		<>
			<div className="single-session h-full w-full flex gap-4 items-stretch overflow-hidden">
				<div className="view overflow-hidden">
					<LocalActions />
					<SingleSessionHeader
						session={session}
						userRole={userData.user_role}
					/>

					<div className="content ms-8 mt-2 max-w-full !overflow-auto">
						<div className="h-full flex gap-8 pt-2">
							<CaseTabs
								session={session}
								cases={cases}
								selectedIndex={selectedIndex}
								onAddTab={handleAddTab}
								onRemoveTab={handleRemoveTab}
								onChange={handleChangeTab}
								onShowDeleteCaseModal={() => setShowDeleteCaseModal(true)}
								onOpenStoryModal={() => setShowSelectStoryModal(true)}
								onOpenPatientModal={() => setShowSelectPatientModal(true)}
							/>

							{((session.status === "Reviewed" && amI("Student")) ||
								(session.status !== "Draft" && amI("Teacher"))) && (
								<aside className="aside pt-[33px] ms-auto">
									{/* session review */}
									{selectedIndex === 0 && (
										<ReviewForm
											target={session}
											title={t(
												canI("review", "SingleSession")
													? "Review session"
													: "Session review",
											)}
											helpText={t(
												canI("review", "SingleSession")
													? "Review the session as a whole.\nYou can see the overview of all cases and their feedback on the first tab."
													: "This is the feedback for the whole session. For more detailed feedback check the cases and prescriptions.",
											)}
											readOnly={!canI("review", "SingleSession")}
											buttonCaption={t("Review session")}
											onClose={() => setRefreshSession(true)}
											onSubmit={handleSubmitReviewSessionForm}
										/>
									)}

									{/* case review */}
									{selectedIndex > 0 && (
										<ReviewForm
											target={currentCase}
											title={t(
												canI("review", "SingleTreatmentPlan")
													? "Review case"
													: "Case review",
											)}
											helpText={t(
												"Review single cases.\nYou can see the overview of all cases and their feedback on the first tab.",
											)}
											readOnly={!canI("review", "SingleSession")}
											buttonCaption={t("Review treatment plan")}
											onClose={() => setRefreshCase(true)}
											onSubmit={handleSubmitReviewCaseForm}
										/>
									)}
								</aside>
							)}
						</div>
					</div>
				</div>
			</div>

			{showEditTitleModal && (
				<SessionNameModal
					description={session.description}
					onClose={() => setShowEditTitleModal(false)}
					onSubmit={(description) => handleUpdateTitle(description)}
				/>
			)}

			{showDeleteSessionModal && (
				<ConfirmationModal
					title={t("Delete session?")}
					message={t(
						"Are you sure you want to delete the session with the ID {{id}}?\nThis operation cannot be undone.",
						{ id: session.id },
					)}
					yesMessage={t("Delete")}
					destructive={true}
					onClose={() => setShowDeleteSessionModal(false)}
					onSubmit={handleDeleteSession}
				/>
			)}

			{showSubmitSessionModal && (
				<ConfirmationModal
					title={
						session.session_type === "Self-Study"
							? t("Request feedback")
							: t("Confirm submit")
					}
					message={t(
						"You are going to ask for feedback. After that, changes in this session are not possible anymore. Are you sure the session is ready for feedback?",
					)}
					yesMessage={t("Submit")}
					onClose={() => setShowSubmitSessionModal(false)}
					onSubmit={handleSubmitSession}
				/>
			)}

			{showSendNotificationModal && (
				<ConfirmationModal
					title={t("Send feedback?")}
					message={getNotificationMessage()}
					yesMessage={t("Send e-mail")}
					onClose={() => setShowSendNotificationModal(false)}
					onSubmit={handleSendNotification}
				/>
			)}

			{showDeleteCaseModal && (
				<ConfirmationModal
					title={t("Delete case?")}
					message={`${t(
						"Are you sure you want to delete the case with the ID",
					)} ${currentCase.id}?`}
					yesMessage={t("Delete")}
					destructive={true}
					onClose={() => setShowDeleteCaseModal(false)}
					onSubmit={handleDeleteCase}
				/>
			)}

			{showSelectStoryModal && (
				<SelectStoryModal
					onClose={() => setShowSelectStoryModal(false)}
					onSubmit={handleSubmitStory}
				/>
			)}

			{showSelectPatientModal && (
				<SelectPatientModal
					patientData={currentCase?.patient}
					onClose={() => setShowSelectPatientModal(false)}
					onSubmit={handleSubmitPatient}
				/>
			)}
		</>
	);
};

SingleSession.displayName = "SingleSession";

export default SingleSession;
