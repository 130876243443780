import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";
import { useHelp } from "context/HelpContext";

// import JwtTimer from "components/JwtTimer/JwtTimer";
import ConfirmationModal from "components/ConfirmationModal";

const TopNav = () => {
  const { clearAuthData } = useAuth();
  const { userData, clearUserData } = useUser();
  const { showHelp } = useHelp();
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { t } = useTranslation();

  const branch = process.env.REACT_APP_BRANCH;

  const getHelloName = () => {
    const fullName = `${userData.first_name} ${userData.last_name}`.trim();
    return fullName || userData.email;
  };

  const handleLogout = () => {
    clearUserData();
    clearAuthData();
    navigate("/login");
  };

  return (
    <div className="topnav h-8 w-full ps-2 pe-4 fixed top-0 bg-slate-200 flex gap-3 items-center justify-end text-sm text-slate-800 leading-none">
      <div className="font-bold text-base tracking-tighter">edups</div>
      <div className="mr-auto">
        {branch === "local" && (
          <div className="px-2 text-sm bg-slate-700 text-white font-bold rounded">
            {branch}
          </div>
        )}
        {/*
        <p className="center text-sm">
          {`${userData.user_role} session`}
          <span className="ml-4">
            <JwtTimer label={t("Remaining session time:")} />
          </span>
        </p>
      */}
      </div>
      <div>{t("Hello, {{ name }}!", { name: getHelloName() })}</div>
      <Link
        className="w-6 h-6 rounded-full no-underline text-slate-600 text-2xl leading-none"
        onClick={() => showHelp()}
        title={t("Help")}
      >
        <i className="block ri-question-fill"></i>
      </Link>
      <Link
        className="w-6 h-6 rounded-full no-underline text-slate-600 text-2xl leading-none"
        to="/profile"
        title={t("Edit profile")}
      >
        <i className="block ri-account-circle-fill"></i>
      </Link>
      <Link onClick={() => setShowLogoutModal(true)}>{t("Log out")}</Link>
      {showLogoutModal && (
        <ConfirmationModal
          title={t("Log out?")}
          message={t("Are you sure you want to log out?")}
          yesMessage={t("Log out")}
          className="w-auto"
          onClose={() => setShowLogoutModal(false)}
          onSubmit={handleLogout}
        />
      )}
    </div>
  );
};

export default TopNav;
